/* eslint-disable @typescript-eslint/no-misused-promises */
import firebase from "firebase/app";
import moment from "moment";
import { CommentProps, HouseWallProps } from "src/@types/house";
import { IProfileBase } from "src/@types/user";
import firebaseService from "../service/service";

export const getHouses = (): Promise<any> => {
    return new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            reject(err);
        }

        firebaseService.db
            .collection("houses")
            .get()
            .then((querySnapshot) => {
                const houses: any[] = [];
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots

                    houses.push({
                        id: doc.id,
                        data: {
                            ...doc.data(),
                            relation: doc.data().relation,
                            startDate: doc.data().startDate.seconds,
                            endDate: doc.data().endDate.seconds,
                        },
                    });

                    // if (!mentors.includes(doc.data().mentorName)){
                    //     mentors.push(doc.data().mentorName)
                    // }

                    // doc.data().stack.map((el:string)=>{
                    //     if (!stackList.includes(el)){
                    //         stackList.push(el)
                    //     }
                    //     return "";
                    // })
                });
                resolve(houses);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getHousesMain = (): Promise<any> => {
    return new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            reject(err);
        }

        firebaseService.db
            .collection("houses")
            .limit(8)
            .get()
            .then((querySnapshot) => {
                const houses: any[] = [];
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots

                    houses.push({
                        id: doc.id,
                        data: {
                            ...doc.data(),
                            relation: doc.data().relation,
                            startDate: doc.data().startDate.seconds,
                            endDate: doc.data().endDate.seconds,
                        },
                    });

                    // if (!mentors.includes(doc.data().mentorName)){
                    //     mentors.push(doc.data().mentorName)
                    // }

                    // doc.data().stack.map((el:string)=>{
                    //     if (!stackList.includes(el)){
                    //         stackList.push(el)
                    //     }
                    //     return "";
                    // })
                });
                resolve(houses);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getHouseById = (houseId: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            reject(err);
        }

        firebaseService.db
            .doc(`houses/${houseId}`)
            .get()
            .then((doc) => {
                let house: any = {};
                let mentor: any = {};
                const subscription: any = {};

                if (doc.exists && doc.data()) {
                    house = {
                        id: doc.id,
                        data: {
                            description: doc.data()?.description ?? "",
                            mentorId: doc.data()?.mentorId ?? "",
                            stack: doc.data()?.stack ?? [],
                            title: doc.data()?.title ?? "",
                            topics: doc.data()?.topics ?? [],
                            date: doc.data()?.date.seconds,
                            duration: doc.data()?.duration,
                            paddle: doc.data()?.paddle,
                            price: doc.data()?.price,
                            relation: doc.data()?.relation,
                        },
                    };

                    firebaseService.db
                        .doc(`mentors/${doc.data()?.mentorId as string}`)
                        .get()
                        .then((data) => {
                            mentor = {
                                ...data.data(),
                            };
                            console.log(data);
                            resolve([house, mentor, subscription]);
                        })
                        .catch((err) => reject(err));
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
};

// get subscribers for a house
export const getHouseSubscribers = (houseId: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            reject(err);
        }

        firebaseService.db
            .collection(`houses/${houseId}/users`)
            .get()
            .then((querySnapshot) => {
                const subscribers: IProfileBase[] = [];
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    subscribers.push({
                        id: doc.id,
                        data: {
                            username: doc.data().username,
                            email: doc.data().email,
                            avatar64: doc.data().avatar64,
                        },
                    });
                });
                resolve(subscribers);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getHouseWallFirst = (houseId: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            reject(err);
        }

        firebaseService.db
            .collection(`houses/${houseId}/wall`)
            .orderBy("published", "desc")
            .limit(10)
            .get()
            .then((querySnapshot) => {
                const wall: HouseWallProps[] = [];
                let lastWallIdNew = 0;
                querySnapshot.forEach((doc) => {
                    wall.push({
                        id: doc.id,
                        data: {
                            content: doc.data().content,
                            title: doc.data().title,
                            author: doc.data().author,
                            published: doc.data().published.seconds,
                            comments: doc.data().comments,
                            upvotes: doc.data().upvotes ?? [],
                            downvotes: doc.data().downvotes ?? [],
                            category: doc.data().category,
                            topics: doc.data().topics ?? [],
                            commentCount: doc.data().commentCount,
                        },
                    });
                    lastWallIdNew = doc.data().published;
                });
                // wall.sort((a, b) => {
                //     return b.published - a.published;
                // });
                resolve({ wall, lastWallId: lastWallIdNew });
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getHouseWallPost = (
    houseId: string,
    postId: string
): Promise<any> => {
    return new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            reject(err);
        }

        firebaseService.db
            .doc(`houses/${houseId}/wall/${postId}`)
            .get()
            .then((querySnapshot) => {
                let wall: HouseWallProps;
                if (querySnapshot.exists) {
                    wall = {
                        id: querySnapshot.id,
                        data: {
                            content: querySnapshot.data()?.content,
                            title: querySnapshot.data()?.title,
                            author: querySnapshot.data()?.author,
                            published: querySnapshot.data()?.published.seconds,
                            comments: querySnapshot.data()?.comments,
                            upvotes: querySnapshot.data()?.upvotes ?? [],
                            downvotes: querySnapshot.data()?.downvotes ?? [],
                            category: querySnapshot.data()?.category,
                            topics: querySnapshot.data()?.topics ?? [],
                            commentCount: querySnapshot.data()?.commentCount,
                        },
                    };
                    resolve({ wall });
                }
                resolve({});
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getHouseWallNext = (
    houseId: string,
    lastWallId: number
): Promise<any> => {
    return new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            reject(err);
        }

        firebaseService.db
            .collection(`houses/${houseId}/wall`)
            .orderBy("published", "desc")
            .startAfter(lastWallId)
            .limit(10)
            .get()
            .then((querySnapshot) => {
                const wall: HouseWallProps[] = [];
                let lastWallIdNew = 0;
                querySnapshot.forEach((doc) => {
                    wall.push({
                        id: doc.id,
                        data: {
                            content: doc.data().content,
                            title: doc.data().title,
                            author: doc.data().author,
                            published: doc.data().published.seconds,
                            comments: doc.data().comments,
                            upvotes: doc.data().upvotes ?? [],
                            downvotes: doc.data().downvotes ?? [],
                            category: doc.data().category,
                            topics: doc.data().topics ?? [],
                            commentCount: doc.data().commentCount,
                        },
                    });
                    lastWallIdNew = doc.data().published;
                });
                // wall.sort((a, b) => {
                //     return b.published - a.published;
                // });
                resolve({ wall, lastWallId: lastWallIdNew });
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const houseWallFileUpload = (
    houseId: string,
    wallId: string,
    files: any[]
): Promise<any> => {
    return new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            reject(err);
        }

        const storageRef = firebaseService.storage.ref();
        console.log("FILES:", files);
        const success: string[] = [];
        files.forEach(async (file) => {
            const fileRef = storageRef.child(
                `houses/${houseId}/wall/${wallId}/${String(file.name)}`
            );
            await fileRef
                .put(file)
                .then((snapshot) => {
                    success.push("success");
                })
                .catch((error) => {
                    success.push("error");
                });
        });
        if (success.includes("error")) {
            reject(new Error("Error uploading files"));
        } else {
            resolve("success");
        }
        /// upload files to firebase storage
    });
};

export const addHouseWallPost = (
    houseId: string,
    wallPost: Record<string, any>
): Promise<any> => {
    return new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            reject(err);
        }

        firebaseService.db
            .collection(`houses/${houseId}/wall`)
            .add({
                ...wallPost,
                published: {
                    seconds: Date.now(),
                },
                upvotes: [],
                downvotes: [],
                commentCount: 0,
                files: wallPost.files.map((file: any) => String(file.name)),
            })
            .then((doc) => {
                houseWallFileUpload(houseId, doc.id, wallPost.files)
                    .then((res) => {
                        if (res === "success") {
                            resolve(doc);
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const editHouseWallPost = (
    houseId: string,
    wallId: string,
    content: string,
    title: string
): Promise<any> => {
    return new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            reject(err);
        }

        firebaseService.db
            .doc(`houses/${houseId}/wall/${wallId}`)
            .update({
                content,
                title,
            })
            .then(() => {
                resolve("success");
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const deleteHouseWallPost = (
    houseId: string,
    wallId: string
): Promise<any> => {
    return new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            reject(err);
        }
        const wallPostCommentsDelete = new Promise((res, rej) => {
            firebaseService.db
                .collection(`houses/${houseId}/wall/${wallId}/comments`)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        firebaseService.db
                            .doc(
                                `houses/${houseId}/wall/${wallId}/comments/${doc.id}`
                            )
                            .delete()
                            .then(() => {
                                res("success");
                            })
                            .catch((error) => {
                                rej(error);
                            });
                    });
                })
                .catch((error) => {
                    rej(error);
                });
        });

        const wallPostUsersDelete = new Promise((res, rej) => {
            firebaseService.db
                .collection(`houses/${houseId}/wall/${wallId}/users`)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        firebaseService.db
                            .doc(
                                `houses/${houseId}/wall/${wallId}/users/${doc.id}`
                            )
                            .delete()
                            .then(() => {
                                resolve("success");
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    });
                })
                .catch((error) => {
                    reject(error);
                });
        });

        const wallPostDelete = new Promise((res, rej) => {
            firebaseService.db
                .doc(`houses/${houseId}/wall/${wallId}`)
                .delete()
                .then(() => {
                    res("success");
                })
                .catch((error) => {
                    rej(error);
                });
        });

        Promise.all([
            wallPostCommentsDelete,
            wallPostUsersDelete,
            wallPostDelete,
        ])
            .then(() => {
                resolve("success");
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/// create function to get collection of comments from a wall post
export const getHouseWallPostComments = (
    houseId: string,
    wallId: string
): Promise<any> => {
    return new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            reject(err);
        }

        firebaseService.db
            .collection(`houses/${houseId}/wall/${wallId}/comments`)
            .orderBy("createdAt", "desc")
            .limit(10)
            .onSnapshot(
                (querySnapshot) => {
                    console.log("WORKED");
                    const comments: CommentProps[] = [];
                    let lastCommentIdNew = 0;
                    querySnapshot.forEach((doc) => {
                        comments.push({
                            id: doc.id,
                            content: doc.data().content,
                            createdAt: doc.data().createdAt,
                            children: doc.data().children ?? [],
                            author: doc.data().author,
                            upvotes: doc.data().upvotes ?? [],
                            downvotes: doc.data().downvotes ?? [],
                        });
                        lastCommentIdNew = doc.data().createdAt;
                    });
                    resolve({ comments, lastCommentId: lastCommentIdNew });
                },
                (error) => {
                    reject(error);
                }
            );
    });
};

/// create function to get collection of next 10 comments from a wall post
export const getHouseWallPostCommentsNext = (
    houseId: string,
    wallId: string,
    lastCommentId: any
): Promise<any> => {
    return new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            reject(err);
        }

        firebaseService.db
            .collection(`houses/${houseId}/wall/${wallId}/comments`)
            .orderBy("createdAt", "desc")
            .startAfter(lastCommentId)
            .limit(10)
            .onSnapshot(
                (querySnapshot) => {
                    const comments: CommentProps[] = [];
                    let lastCommentIdNew = 0;
                    querySnapshot.forEach((doc) => {
                        comments.push({
                            id: doc.id,
                            content: doc.data().content,
                            createdAt: doc.data().createdAt,
                            children: doc.data().children ?? [],
                            author: doc.data().author,
                            upvotes: doc.data().upvotes ?? [],
                            downvotes: doc.data().downvotes ?? [],
                        });
                        lastCommentIdNew = doc.data().createdAt;
                    });
                    resolve({ comments, lastCommentId: lastCommentIdNew });
                },
                (error) => {
                    reject(error);
                }
            );
    });
};

/// create logic of upvoting or downvoting a house wall post
export const upvoteHouseWallPost = (
    houseId: string,
    wallId: string,
    userId: string
): Promise<any> => {
    return new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            reject(err);
        }

        firebaseService.db
            .doc(`houses/${houseId}/wall/${wallId}`)
            .set(
                {
                    upvotes: firebase.firestore.FieldValue.arrayUnion(userId),
                    downvotes:
                        firebase.firestore.FieldValue.arrayRemove(userId),
                },
                { merge: true }
            )
            .then(() => {
                resolve("success");
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const downvoteHouseWallPost = (
    houseId: string,
    wallId: string,
    userId: string
): Promise<any> => {
    return new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            reject(err);
        }

        firebaseService.db
            .doc(`houses/${houseId}/wall/${wallId}`)
            .set(
                {
                    downvotes: firebase.firestore.FieldValue.arrayUnion(userId),
                    upvotes: firebase.firestore.FieldValue.arrayRemove(userId),
                },
                { merge: true }
            )
            .then(() => {
                resolve("success");
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const updateHouseWallPostCommentCount = (
    houseId: string,
    wallId: string,
    increment: number
): Promise<any> => {
    return new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            reject(err);
        }

        firebaseService.db
            .doc(`houses/${houseId}/wall/${wallId}`)
            .update({
                commentCount:
                    firebase.firestore.FieldValue.increment(increment),
            })
            .then(() => {
                resolve("success");
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const houseWallListener = (houseId: string, wallId: string) => {
    return new Promise((res, rej) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
        }

        firebaseService.db
            .collection(`houses/${houseId}/wall`)
            .onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    if (change.type === "added") {
                        res("added");
                    }
                });
            });
    });
};
