import { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import firebaseService from "../service";
import { MyProfile } from "../store/actions/profileActions";
import { useAppSelector, useAppDispatch } from "../store/hooks";

export const PrivateRoute = ({ ...rest }: any) => {
    const { currentUser } = firebaseService.auth;
    const dispatch = useAppDispatch();
    const { myProfile } = useAppSelector((state) => state.profile);
    useEffect(() => {
        if (currentUser && currentUser.email && !myProfile) {
            dispatch(MyProfile(currentUser.email));
        }
    }, [currentUser, myProfile]);
    return currentUser ? (
        <Route {...rest} />
    ) : (
        <Redirect to={{ pathname: "/signin" }} />
    );
};
