/* eslint-disable @typescript-eslint/no-floating-promises */
import { CommentProps, HouseWallProps } from "src/@types/house";
import { IProfileBase } from "src/@types/user";
import {
    housesSuccess,
    housesFail,
    housesFiltered,
    filterApplied,
    houseFail,
    houseSuccess,
    houseWallSuccess,
    houseWallFail,
    houseWallNextSuccess,
    houseSubscribersSuccess,
    houseSubscribersFail,
    houseWallUpvoteSuccess,
    houseWallDownvoteSuccess,
    houseWallVoteLoading,
    houseWallPostSuccess,
    houseWallPostFail,
    houseWallPostLoading,
    wallCommentsCountUpdate,
    houseWallLoading,
} from "../slices/houses";

import {
    addHouseWallPost,
    deleteHouseWallPost,
    downvoteHouseWallPost,
    editHouseWallPost,
    getHouseById,
    getHouses,
    getHousesMain,
    getHouseSubscribers,
    getHouseWallFirst,
    getHouseWallNext,
    getHouseWallPost,
    updateHouseWallPostCommentCount,
    upvoteHouseWallPost,
} from "../../api/houses";

import type { AppDispatch } from "../store";

export const Houses =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            await getHouses().then(
                (houses: any) => {
                    const houseList = houses as any[];
                    // const mentorsList = arr[1] as string[];
                    // const levelsList = arr[2] as string[];
                    // const stackList = arr[2] as string[];
                    dispatch(
                        housesSuccess({
                            houses: houseList,
                        })
                    );
                },
                (error) => {
                    console.error(error); // Stacktrace
                    dispatch(housesFail({ error: error.message }));
                }
            );
        } catch (error) {
            dispatch(housesFail({ error: error.message }));
        }
    };

export const HousesMain =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            await getHousesMain().then(
                (houses: any) => {
                    const houseList = houses as any[];
                    // const mentorsList = arr[1] as string[];
                    // const levelsList = arr[2] as string[];
                    // const stackList = arr[2] as string[];
                    dispatch(
                        housesSuccess({
                            houses: houseList,
                        })
                    );
                },
                (error) => {
                    console.error(error); // Stacktrace
                    dispatch(housesFail({ error: error.message }));
                }
            );
        } catch (error) {
            dispatch(housesFail({ error: error.message }));
        }
    };

export const HouseById =
    (id: string, userId?: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            await getHouseById(id).then(
                (result: any) => {
                    const house = result[0] as any[];
                    const mentor = result[1] as any[];
                    dispatch(houseSuccess({ house, mentor }));
                },
                (error) => {
                    console.error(error); // Stacktrace
                    dispatch(houseFail({ error: error.message }));
                }
            );
        } catch (error) {
            dispatch(houseFail({ error: error.message }));
        }
    };

export const HouseWall =
    (id: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(houseWallLoading());
        try {
            await getHouseWallFirst(id).then(
                (result: any) => {
                    const houseWall = result.wall as HouseWallProps[];
                    const { lastWallId } = result;
                    dispatch(houseWallSuccess({ houseWall, lastWallId }));
                },
                (error) => {
                    console.error(error); // Stacktrace
                    dispatch(houseWallFail({ error: error.message }));
                }
            );
        } catch (error) {
            dispatch(houseWallFail({ error: error.message }));
        }
    };

export const HouseWallNext =
    (id: string, date: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(houseWallLoading());
        try {
            await getHouseWallNext(id, date).then(
                (result: any) => {
                    const houseWall = result.wall as HouseWallProps[];
                    const { lastWallId } = result;
                    dispatch(houseWallNextSuccess({ houseWall, lastWallId }));
                },
                (error) => {
                    console.error(error); // Stacktrace
                    dispatch(houseWallFail({ error: error.message }));
                }
            );
        } catch (error) {
            dispatch(houseWallFail({ error: error.message }));
        }
    };

export const HouseWallPost =
    (houseId: string, postId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            dispatch(houseWallPostLoading());
            await getHouseWallPost(houseId, postId).then(
                (result: any) => {
                    const houseWallPost = result.wall as HouseWallProps;
                    dispatch(houseWallPostSuccess({ wall: houseWallPost }));
                },
                (error) => {
                    console.error(error); // Stacktrace
                    dispatch(houseWallPostFail({ error: error.message }));
                }
            );
        } catch (error: any) {
            dispatch(houseWallPostFail({ error: error?.message }));
        }
    };

// create action to get house subscribers
export const HouseSubscribers =
    (id: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            await getHouseSubscribers(id).then(
                (result: any) => {
                    const subscribers = result as IProfileBase[];
                    dispatch(
                        houseSubscribersSuccess({
                            houseSubscribers: subscribers,
                        })
                    );
                },
                (error) => {
                    console.error(error); // Stacktrace
                    dispatch(houseSubscribersFail({ error: error.message }));
                }
            );
        } catch (error) {
            dispatch(houseSubscribersFail({ error: error.message }));
        }
    };

// create action to add new wall post
export const AddWallPost =
    (id: string, post: Record<string, any>) =>
    async (dispatch: any): Promise<void> => {
        try {
            await addHouseWallPost(id, post)
                .then((result: any) => {
                    const wall = result.wall as HouseWallProps[];
                    const { lastWallId } = result;
                    dispatch(houseWallSuccess({ houseWall: wall, lastWallId }));
                    dispatch(HouseWall(id));
                })
                .catch((e) => {
                    console.error(e);
                    dispatch(houseWallFail({ error: e.message }));
                });
        } catch (error) {
            dispatch(houseWallFail({ error: error.message }));
        }
    };

// create action to edit wall post
export const EditWallPost =
    (houseId: string, wallId: string, content: string, title: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            await editHouseWallPost(houseId, wallId, content, title).then(
                async (result: any) => {
                    await dispatch(HouseWall(houseId));
                },
                (error) => {
                    console.error(error); // Stacktrace
                    dispatch(houseWallFail({ error: error.message }));
                }
            );
        } catch (error) {
            dispatch(houseWallFail({ error: error.message }));
        }
    };

// create action to delete wall post
export const DeleteWallPost =
    (houseId: string, wallId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            await deleteHouseWallPost(houseId, wallId).then(
                (result: any) => {
                    dispatch(HouseWall(houseId));
                },
                (error) => {
                    console.error(error); // Stacktrace
                    dispatch(houseWallFail({ error: error.message }));
                }
            );
        } catch (error) {
            dispatch(houseWallFail({ error: error.message }));
        }
    };

export const HouseWallUpvote =
    (houseId: string, wallId: string, userId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(houseWallVoteLoading());
        try {
            await upvoteHouseWallPost(houseId, wallId, userId).then(
                (result: any) => {
                    dispatch(houseWallUpvoteSuccess({ wallId, userId }));
                }
            );
        } catch (error) {
            dispatch(houseWallFail({ error: error.message }));
        }
    };

export const HouseWallDownvote =
    (houseId: string, wallId: string, userId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(houseWallVoteLoading());
        try {
            await downvoteHouseWallPost(houseId, wallId, userId).then(
                (result: any) => {
                    dispatch(houseWallDownvoteSuccess({ wallId, userId }));
                }
            );
        } catch (error) {
            dispatch(houseWallFail({ error: error.message }));
        }
    };

export const HouseWallPostCommentUpdate =
    (houseId: string, wallId: string, increment: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            await updateHouseWallPostCommentCount(
                houseId,
                wallId,
                increment
            ).then((result: any) => {
                dispatch(wallCommentsCountUpdate({ wallId, increment }));
            });
        } catch (error) {
            dispatch(houseWallFail({ error: error.message }));
        }
    };
