import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IStaff } from "src/@types/staff";

export interface StaffState {
    getStaffError?: string;
    getStaffLoading?: boolean;
    mentors: IStaff[];
    trackers: IStaff[];
    curators: IStaff[];
}

const initialState: StaffState = {
    getStaffError: "",
    getStaffLoading: true,
    mentors: [],
    trackers: [],
    curators: [],
};

const staffSlice = createSlice({
    name: "staff",
    initialState,
    reducers: {
        getStaffStart(state) {
            state.getStaffLoading = true;
        },
        getStaffSuccess(
            state,
            action: PayloadAction<{
                mentors: IStaff[];
                trackers: IStaff[];
                curators: IStaff[];
            }>
        ) {
            const {
                payload: { mentors, trackers, curators },
            } = action;
            state.getStaffLoading = false;
            state.mentors = mentors;
            state.trackers = trackers;
            state.curators = curators;
        },
        getStaffError(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            state.getStaffLoading = false;
            state.getStaffError = action.payload.error;
        },
    },
});

export const { getStaffStart, getStaffSuccess, getStaffError } =
    staffSlice.actions;

export default staffSlice.reducer;
