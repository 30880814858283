import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICourse } from "src/@types/course";

export enum RecentCoursesStatus {
    Loaded,
    Loading,
    Unloaded,
}

export interface RecentCoursesState {
    areLoaded: RecentCoursesStatus;
    recentCoursesError: string | null;
    courses: ICourse[];
}

const initialState: RecentCoursesState = {
    areLoaded: RecentCoursesStatus.Loading,
    recentCoursesError: null,
    courses: [],
};

const recentCoursesSlice = createSlice({
    name: "recentCourses",
    initialState,
    reducers: {
        recentCoursesSuccess(
            state,
            action: PayloadAction<{
                courses: ICourse[];
            }>
        ) {
            const {
                payload: { courses },
            } = action;
            state.areLoaded = RecentCoursesStatus.Loaded;
            state.courses = courses;
            state.recentCoursesError = null;
        },
        recentCoursesFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.areLoaded = RecentCoursesStatus.Unloaded;
            state.recentCoursesError = error;
        },
    },
});

export const { recentCoursesSuccess, recentCoursesFail } =
    recentCoursesSlice.actions;
export default recentCoursesSlice.reducer;
