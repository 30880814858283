import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICourse, ISyllabusItem } from "src/@types/course";

export enum CoursesStatus {
    Loaded,
    Loading,
    Unloaded,
}

export interface CoursesState {
    areLoaded: CoursesStatus;
    coursesError: string | null;
    courses: ICourse[];
    allCourses: ICourse[];
    filter: string;
    subject: string;
    filtered: boolean;
    course: ICourse | null;
    isLoading: boolean;
    syllabus: any[];
    courseError: string | null;
}

const initialState: CoursesState = {
    areLoaded: CoursesStatus.Loading,
    coursesError: null,
    courses: [],
    allCourses: [],
    filter: "all",
    subject: "all",
    filtered: false,
    course: null,
    isLoading: false,
    syllabus: [],
    courseError: null,
};

const coursesSlice = createSlice({
    name: "courses",
    initialState,
    reducers: {
        coursesSuccess(
            state,
            action: PayloadAction<{
                courses: ICourse[];
            }>
        ) {
            const {
                payload: { courses },
            } = action;
            state.areLoaded = CoursesStatus.Loaded;
            state.courses = courses;
            state.allCourses = courses;
            state.coursesError = null;
        },
        coursesFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.areLoaded = CoursesStatus.Unloaded;
            state.coursesError = error;
        },
        filterApplied(
            state,
            action: PayloadAction<{
                filtered: boolean;
            }>
        ) {
            const {
                payload: { filtered },
            } = action;
            state.filtered = filtered;
        },
        filterChanged(
            state,
            action: PayloadAction<{
                filter: string;
            }>
        ) {
            const {
                payload: { filter },
            } = action;
            state.filter = filter;
        },
        subjectChanged(
            state,
            action: PayloadAction<{
                subject: string;
            }>
        ) {
            const {
                payload: { subject },
            } = action;
            state.subject = subject;
        },
        coursesFiltered(
            state,
            action: PayloadAction<{
                courses: ICourse[];
            }>
        ) {
            const {
                payload: { courses },
            } = action;
            state.courses = courses;
        },
        courseLoading(state) {
            state.isLoading = true;
        },
        courseLoadSuccess(
            state,
            action: PayloadAction<{
                course: ICourse;
                syllabus: any[];
            }>
        ) {
            const {
                payload: { course, syllabus },
            } = action;
            state.isLoading = false;
            state.course = course;
            state.syllabus = syllabus;
            state.courseError = null;
        },
        courseLoadFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.isLoading = false;
            state.courseError = error;
        },
    },
});

export const {
    coursesSuccess,
    coursesFail,
    filterChanged,
    subjectChanged,
    coursesFiltered,
    filterApplied,
    courseLoadFail,
    courseLoadSuccess,
    courseLoading,
} = coursesSlice.actions;
export default coursesSlice.reducer;
