const prodConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
};

// const devConfig = {
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_FIREBASE_APP_ID,
//     databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
// };

const devConfig = {
    apiKey: "AIzaSyAk9rhZ1obwi_FHBiNx5fvfVti3yzIRnqQ",
    authDomain: "makers-courses-dev.firebaseapp.com",
    projectId: "makers-courses-dev",
    storageBucket: "makers-courses-dev.appspot.com",
    messagingSenderId: "792552178345",
    appId: "1:792552178345:web:f82fa213a0568751775fb8",
    databaseURL: "makers-courses-dev.firebaseio.com",
};

const config =
    process.env.REACT_APP_NODE_ENV === "development" ? devConfig : prodConfig;

export default config;
