import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProfile } from "src/@types/user";

export interface ProfileErrors {
    profileError: string | null;
    myProfileError: string | null;
}

export interface ProfileState {
    isLoading: boolean;
    isMyLoading: boolean;
    success: boolean;
    profile?: IProfile;
    myProfile?: IProfile;
    profileErrors: ProfileErrors;
    balance?: number;
    mentors: Record<string, any>;
    subscriptions: Record<string, any>;
    projects: Record<string, any>;
    error: string | null;
    progresses?: Record<string, any>;
}

const initialState: ProfileState = {
    profileErrors: {
        profileError: null,
        myProfileError: null,
    },
    isLoading: true,
    isMyLoading: true,
    success: false,
    mentors: {},
    subscriptions: {},
    error: null,
    projects: {},
    progresses: {},
};

const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        getProfilePending(state) {
            state.isLoading = true;
        },
        destroyMyProfile(state) {
            state.myProfile = undefined;
            state.balance = undefined;
        },
        getProfileSuccess(
            state,
            action: PayloadAction<{
                profile: IProfile;
            }>
        ) {
            const {
                payload: { profile },
            } = action;
            state.isLoading = false;
            state.profile = profile;
            state.profileErrors.profileError = null;
            state.success = true;
        },
        getProfileFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.isLoading = false;
            state.profileErrors.profileError = error;
            state.success = false;
        },
        getMyProfileSuccess(
            state,
            action: PayloadAction<{
                myProfile: IProfile;
            }>
        ) {
            const {
                payload: { myProfile },
            } = action;
            state.myProfile = myProfile;
            state.balance = myProfile.balance;
            if (myProfile.subscriptions) {
                state.subscriptions = myProfile.subscriptions;
            }
            if (myProfile.projects) {
                state.projects = myProfile.projects;
            }
            state.profileErrors.myProfileError = null;
            state.isMyLoading = false;
        },
        updateBalance(
            state,
            action: PayloadAction<{
                payment: number;
            }>
        ) {
            const {
                payload: { payment },
            } = action;
            if (!state.balance) {
                state.balance = payment;
            } else {
                const newBalance = state.balance + payment;
                state.balance = newBalance;
            }
        },
        getMyProfileFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.profileErrors.myProfileError = error;
            state.isMyLoading = false;
        },
        getSubscriptionPending(state) {
            state.isLoading = true;
        },
        getSubscriptionSuccess(
            state,
            action: PayloadAction<{
                subscriptions: Record<string, any>;
            }>
        ) {
            const {
                payload: { subscriptions },
            } = action;
            state.isLoading = false;
            state.subscriptions = subscriptions;
            state.error = "";
            state.success = true;
        },
        getSubscriptionFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.isLoading = false;
            state.error = error;
            state.success = false;
        },
        getMyProfileProgressessSuccess(
            state,
            action: PayloadAction<{
                progresses: Record<string, any>;
            }>
        ) {
            const {
                payload: { progresses },
            } = action;
            state.progresses = progresses;
        },
        myProfileProgressesUpdate(
            state,
            action: PayloadAction<{
                taskId: string;
            }>
        ) {
            const {
                payload: { taskId },
            } = action;
            state.progresses = { ...state.progresses, [taskId]: true };
        },
    },
});

export const {
    updateBalance,
    destroyMyProfile,
    getProfilePending,
    getProfileSuccess,
    getProfileFail,
    getMyProfileSuccess,
    getMyProfileFail,
    getSubscriptionPending,
    getSubscriptionSuccess,
    getSubscriptionFail,
    getMyProfileProgressessSuccess,
    myProfileProgressesUpdate,
} = profileSlice.actions;

export default profileSlice.reducer;
