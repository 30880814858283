import { FC, useEffect, useState } from "react";
import App from "./App";
import Preloader from "./components/preloader";
import firebaseService from "./service/service";

const Auth: FC = () => {
    const [userLoaded, setUserLoaded] = useState(false);

    useEffect(() => {
        const unregisterAuthObserver = firebaseService.auth.onAuthStateChanged(
            (user) => {
                if (!userLoaded) {
                    setUserLoaded(true);
                }
            }
        );
        return () => unregisterAuthObserver();
    }, []);

    return (
        <>
            {(() => {
                if (userLoaded) {
                    return <App />;
                }
                return (
                    <div>
                        <Preloader />
                    </div>
                );
            })()}
        </>
    );
};

export default Auth;
