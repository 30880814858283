import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum ProjectsStatus {
    Loaded,
    Loading,
    Unloaded,
}

export enum ProjectStatus {
    Loaded,
    Loading,
    Unloaded,
}

export interface ProjectsState {
    areLoaded: ProjectsStatus;
    error: string;
    projects: any[];
    companies: string[];
    allProjects: any[];
    filter: string;
    subject: string;
    filtered: boolean;
    project: any;
    isLoaded: ProjectStatus;
    company: any;
    subscription: any;
    stackList: string[];
    levelsList: string[];
}

const initialState: ProjectsState = {
    areLoaded: ProjectsStatus.Loading,
    error: "",
    projects: [],
    companies: [],
    stackList: [],
    levelsList: [],
    allProjects: [],
    filter: "all",
    subject: "all",
    filtered: false,
    project: {},
    company: {},
    subscription: {},
    isLoaded: ProjectStatus.Loading,
};

const projectsSlice = createSlice({
    name: "projects",
    initialState,
    reducers: {
        projectsSuccess(
            state,
            action: PayloadAction<{
                projects: any[];
                companies: string[];
                stackList: string[];
                levelsList: string[];
            }>
        ) {
            const {
                payload: { projects, companies, levelsList, stackList },
            } = action;
            state.areLoaded = ProjectsStatus.Loaded;
            state.projects = projects;
            state.allProjects = projects;
            state.companies = companies;
            state.levelsList = levelsList;
            state.stackList = stackList;
            state.error = "";
        },
        projectSuccess(
            state,
            action: PayloadAction<{
                project: any;
                company: any;
            }>
        ) {
            const {
                payload: { project, company },
            } = action;
            state.isLoaded = ProjectStatus.Loaded;
            state.project = project;
            state.company = company;
            state.error = "";
        },
        projectFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.isLoaded = ProjectStatus.Unloaded;
            state.error = error;
        },
        projectsFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.areLoaded = ProjectsStatus.Unloaded;
            state.error = error;
        },
        filterApplied(
            state,
            action: PayloadAction<{
                filtered: boolean;
            }>
        ) {
            const {
                payload: { filtered },
            } = action;
            state.filtered = filtered;
        },
        filterChanged(
            state,
            action: PayloadAction<{
                filter: string;
            }>
        ) {
            const {
                payload: { filter },
            } = action;
            state.filter = filter;
        },
        subjectChanged(
            state,
            action: PayloadAction<{
                subject: string;
            }>
        ) {
            const {
                payload: { subject },
            } = action;
            state.subject = subject;
        },
        projectsFiltered(
            state,
            action: PayloadAction<{
                projects: any[];
            }>
        ) {
            const {
                payload: { projects },
            } = action;
            state.projects = projects;
        },
    },
});

export const {
    projectsSuccess,
    projectsFail,
    filterChanged,
    subjectChanged,
    projectsFiltered,
    filterApplied,
    projectFail,
    projectSuccess,
} = projectsSlice.actions;
export default projectsSlice.reducer;
