import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBlocks, ICourse, ISyllabusItem } from "src/@types/course";
import {
    ICodeCheckResult,
    ILesson,
    ILessonFile,
    ILessonInput,
    IQuizQuestion,
} from "src/@types/lessons";

export interface IdeErrors {
    questionError: string | null;
    lessonError: string | null;
    progressError: string | null;
    downloadMaterialError: string | null;
    attemptError: string | null;
    codeCheckError: string | null;
    syllabusError: string | null;
    removeSkippedError: string | null;
    skipTaskError: string | null;
}

export interface IdeState {
    ideErrors: IdeErrors;
    isLoading: boolean;
    syllabusIsLoading: boolean;
    isQuestionLoading: boolean;
    success: boolean;
    currentLesson?: ILesson;
    activeLesson?: ILesson;
    currentQuestion?: IQuizQuestion;
    questionImgUrl?: string;
    correctAnswers: number;
    quizStatus: "notStarted" | "started" | "finished" | "timeup";
    answerChecking: boolean;
    codeRunning?: boolean;
    codeCheckResult?: ICodeCheckResult;
    quizAttempts: Record<string, number>;
    topics: Record<string, ISyllabusItem[]>;
    quizes: Record<string, ISyllabusItem[]>;
    tasks: Record<string, ISyllabusItem[]>;
    blocksData: Record<string, string[]>;
    topicNames: string[];
    blocksList: string[];
    activeTopic: string;
    indexOfActiveTopic: number;
    indexOfCurrentTopic: number;
    rightSidebarOpen: boolean;
    currentCourse?: ICourse;
    frozen?: boolean;
    quizQuestionsOrder: number[];
    skippedTasks?: Record<string, boolean>;
    currentQuizAnswers: Record<string, string | Record<string, any>>[];
}

const initialState: IdeState = {
    ideErrors: {
        questionError: null,
        lessonError: null,
        progressError: null,
        downloadMaterialError: null,
        attemptError: null,
        codeCheckError: null,
        syllabusError: null,
        removeSkippedError: null,
        skipTaskError: null,
    },
    isLoading: true,
    syllabusIsLoading: true,
    isQuestionLoading: true,
    success: false,
    correctAnswers: 0,
    quizStatus: "notStarted",
    answerChecking: false,
    quizAttempts: {},
    topics: {},
    quizes: {},
    tasks: {},
    blocksData: {},
    topicNames: [],
    blocksList: [],
    activeTopic: "",
    indexOfActiveTopic: -1,
    indexOfCurrentTopic: -1,
    rightSidebarOpen: false,
    quizQuestionsOrder: [],
    currentQuizAnswers: [],
};

const ideSlice = createSlice({
    name: "ide",
    initialState,
    reducers: {
        setCurrentQuizAnswers(
            state,
            action: PayloadAction<
                Record<string, string | Record<string, any>>[]
            >
        ) {
            state.currentQuizAnswers = action.payload;
        },
        setSkipped(
            state,
            action: PayloadAction<{
                skipped?: Record<string, boolean>;
            }>
        ) {
            const {
                payload: { skipped },
            } = action;
            state.skippedTasks = skipped;
        },
        skipTaskSuccess(
            state,
            action: PayloadAction<{
                newTopic: string | null;
                indexOfNewTopic: number | null;
            }>
        ) {
            const {
                payload: { newTopic, indexOfNewTopic },
            } = action;

            if (state.activeLesson) {
                const topics: Record<string, ISyllabusItem[]> = JSON.parse(
                    JSON.stringify(state.topics)
                );
                const activeLessonId = state.activeLesson.id;
                // const indexOfActiveLesson = topics[state.activeTopic].findIndex(
                //     (t) => {
                //         return t.id === activeLessonId;
                //     }
                // );
                // topics[state.activeTopic][indexOfActiveLesson].past = true;
                const indexOfActiveLesson = topics[state.activeTopic].findIndex(
                    (t) => {
                        return t.id === activeLessonId;
                    }
                );
                state.topics = topics;
                if (indexOfNewTopic)
                    state.indexOfCurrentTopic = indexOfNewTopic;
            }
            state.ideErrors.skipTaskError = null;
        },
        skipTaskFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.ideErrors.skipTaskError = error;
        },
        removeSkippedSuccess(state) {
            state.ideErrors.removeSkippedError = null;
        },
        removeSkippedFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.ideErrors.removeSkippedError = error;
        },
        setRightSidebarOpen(
            state,
            action: PayloadAction<{
                sidebarOpen: boolean;
            }>
        ) {
            const {
                payload: { sidebarOpen },
            } = action;
            state.rightSidebarOpen = sidebarOpen;
        },
        codeCheckFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.codeRunning = false;
            state.ideErrors.codeCheckError = error;
        },
        setCodeCheckResult(
            state,
            action: PayloadAction<{
                result: ICodeCheckResult;
            }>
        ) {
            const {
                payload: { result },
            } = action;
            state.codeRunning = false;
            state.codeCheckResult = result;
        },
        setCodeRunning(
            state,
            action: PayloadAction<{
                running: boolean;
            }>
        ) {
            const {
                payload: { running },
            } = action;
            state.codeRunning = running;
            if (running) {
                state.codeCheckResult = undefined;
                state.ideErrors.codeCheckError = null;
            }
        },
        setAnswerChecking(
            state,
            action: PayloadAction<{
                answerChecking: boolean;
            }>
        ) {
            const {
                payload: { answerChecking },
            } = action;
            state.answerChecking = answerChecking;
        },
        setQuizStatus(
            state,
            action: PayloadAction<{
                status: "notStarted" | "started" | "finished" | "timeup";
            }>
        ) {
            const {
                payload: { status },
            } = action;
            state.quizStatus = status;
        },
        setCorrectAnswer(
            state,
            action: PayloadAction<{
                correctAnswers: number;
            }>
        ) {
            const {
                payload: { correctAnswers },
            } = action;
            state.correctAnswers = correctAnswers;
        },
        getQuestionImgUrl(
            state,
            action: PayloadAction<{
                questionImgUrl: string;
            }>
        ) {
            const {
                payload: { questionImgUrl },
            } = action;
            state.questionImgUrl = questionImgUrl;
        },
        getLessonPending(state) {
            state.isLoading = true;
        },
        getCurrentLessonSuccess(
            state,
            action: PayloadAction<{
                currentLesson: ILesson;
                frozen?: boolean;
                sameCourse: boolean;
            }>
        ) {
            const {
                payload: { currentLesson, frozen, sameCourse },
            } = action;
            state.isLoading = false;
            state.frozen = frozen;
            state.currentLesson = currentLesson;
            if (!state.activeLesson || !sameCourse) {
                state.activeLesson = currentLesson;

                if ("quiz" in currentLesson) {
                    const arr = [];
                    while (arr.length < currentLesson.questions) {
                        const r =
                            Math.floor(
                                Math.random() * currentLesson.questions
                            ) + 1;
                        if (arr.indexOf(r) === -1) arr.push(r);
                    }
                    state.quizQuestionsOrder = arr;
                    state.correctAnswers = 0;
                }

                if (state.quizStatus !== "notStarted")
                    state.quizStatus = "notStarted";
                state.success = true;
            }
            state.codeCheckResult = undefined;
            state.ideErrors.lessonError = null;
        },
        getActiveLessonSuccess(
            state,
            action: PayloadAction<{
                activeLesson: ILesson;
                topicName: string;
                topicIndex: number;
            }>
        ) {
            const {
                payload: { activeLesson, topicName, topicIndex },
            } = action;
            state.isLoading = false;
            state.activeLesson = activeLesson;
            if (topicName !== state.activeTopic) {
                state.activeTopic = topicName;
                state.indexOfActiveTopic = topicIndex;
            }

            if ("quiz" in activeLesson) {
                const arr = [];
                while (arr.length < activeLesson.questions) {
                    const r =
                        Math.floor(Math.random() * activeLesson.questions) + 1;
                    if (arr.indexOf(r) === -1) arr.push(r);
                }
                state.quizQuestionsOrder = arr;
                state.correctAnswers = 0;
            }

            if (state.quizStatus !== "notStarted")
                state.quizStatus = "notStarted";
            state.ideErrors.lessonError = null;
            state.success = true;
            state.codeCheckResult = undefined;
        },
        getLessonFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.isLoading = false;
            state.ideErrors.lessonError = error;
            state.success = false;
        },
        getSyllabusPending(state) {
            state.syllabusIsLoading = true;
        },
        getSyllabusSuccess(
            state,
            action: PayloadAction<{
                course: ICourse;
                lastLesson: string;
            }>
        ) {
            const {
                payload: { course, lastLesson },
            } = action;
            state.syllabusIsLoading = false;
            state.currentCourse = course;
            if (course.syllabus) {
                const topics: Record<string, ISyllabusItem[]> = {};
                const blocksData: Record<string, string[]> = {};
                const topicNames: string[] = [];
                const quizes: Record<string, ISyllabusItem[]> = {};
                const tasks: Record<string, ISyllabusItem[]> = {};
                let past = true;
                course.syllabus.forEach((item, index) => {
                    if (item.id === lastLesson) {
                        state.activeTopic = item.topic;
                        past = false;
                    }
                    if (
                        item.category === "theory" ||
                        item.category === "practice" ||
                        item.category === "solution"
                    ) {
                        if (topics[item.topic]) {
                            topics[item.topic].push(
                                past ? { ...item, past: true } : item
                            );
                        } else {
                            topics[item.topic] = [
                                past ? { ...item, past: true } : item,
                            ];

                            topicNames.push(item.topic);
                        }
                        if (blocksData[item.block]) {
                            if (!blocksData[item.block].includes(item.topic)) {
                                blocksData[item.block].push(item.topic);
                            }
                        } else {
                            blocksData[item.block] = [];
                            blocksData[item.block].push(item.topic);
                        }
                    } else if (item.type === "quiz") {
                        if (quizes[item.topic]) {
                            quizes[item.topic].push(
                                past ? { ...item, past: true } : item
                            );
                        } else {
                            quizes[item.topic] = [
                                past ? { ...item, past: true } : item,
                            ];
                        }
                    } else if (item.category === "task") {
                        if (tasks[item.topic]) {
                            tasks[item.topic].push(
                                past ? { ...item, past: true } : item
                            );
                        } else {
                            tasks[item.topic] = [
                                past ? { ...item, past: true } : item,
                            ];
                        }
                    }
                });
                state.blocksList = course.blocks;
                state.topicNames = topicNames;
                state.indexOfActiveTopic = state.topicNames.indexOf(
                    state.activeTopic
                );
                state.indexOfCurrentTopic = state.indexOfActiveTopic;
                state.topics = topics;
                state.quizes = quizes;
                state.tasks = tasks;
                state.blocksData = blocksData;
            }
            state.ideErrors.syllabusError = null;
            state.success = true;
        },
        getSyllabusFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.syllabusIsLoading = false;
            state.ideErrors.syllabusError = error;
            state.success = false;
        },
        getQuestionPending(state) {
            state.isQuestionLoading = true;
            state.questionImgUrl = undefined;
        },
        getQuestionSuccess(
            state,
            action: PayloadAction<{
                currentQuestion: IQuizQuestion;
            }>
        ) {
            const {
                payload: { currentQuestion },
            } = action;
            state.isQuestionLoading = false;
            state.currentQuestion = currentQuestion;
            state.ideErrors.questionError = null;
            state.success = true;

            const quizQuestionsOrder = [...state.quizQuestionsOrder];
            quizQuestionsOrder.pop();
            state.quizQuestionsOrder = quizQuestionsOrder;
        },
        getQuestionFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.isQuestionLoading = false;
            state.ideErrors.questionError = error;
            state.success = false;
        },
        addQuizAttemptFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.ideErrors.attemptError = error;
        },
        downloadMaterialFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.ideErrors.downloadMaterialError = error;
        },
        setQuizAttempts(
            state,
            action: PayloadAction<{
                quizAttempts: Record<string, number>;
            }>
        ) {
            const {
                payload: { quizAttempts },
            } = action;
            state.quizAttempts = quizAttempts;
        },
        setQuizAttempt(
            state,
            action: PayloadAction<{
                currentAttempt: number;
                lesson: string;
            }>
        ) {
            const {
                payload: { currentAttempt, lesson },
            } = action;
            state.quizAttempts[lesson] = currentAttempt + 1;
            state.correctAnswers = 0;
        },
        updateProgressSuccess(
            state,
            action: PayloadAction<{
                newTopic: string | null;
                indexOfNewTopic: number | null;
            }>
        ) {
            const {
                payload: { newTopic, indexOfNewTopic },
            } = action;
            if (state.activeLesson) {
                const topics: Record<string, ISyllabusItem[]> = JSON.parse(
                    JSON.stringify(state.topics)
                );
                const activeLessonId = state.activeLesson.id;
                const indexOfActiveLesson = topics[state.activeTopic].findIndex(
                    (t) => {
                        return t.id === activeLessonId;
                    }
                );
                topics[state.activeTopic][indexOfActiveLesson].past = true;

                state.topics = topics;
                if (indexOfNewTopic)
                    state.indexOfCurrentTopic = indexOfNewTopic;
            }
            state.ideErrors.progressError = null;
        },
        updateProgressFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.ideErrors.progressError = error;
        },
    },
});

export const {
    setCurrentQuizAnswers,
    skipTaskSuccess,
    skipTaskFail,
    setSkipped,
    removeSkippedSuccess,
    removeSkippedFail,
    updateProgressSuccess,
    updateProgressFail,
    getActiveLessonSuccess,
    setRightSidebarOpen,
    setQuizAttempts,
    codeCheckFail,
    setCodeCheckResult,
    setCodeRunning,
    setAnswerChecking,
    setQuizStatus,
    setCorrectAnswer,
    getQuestionImgUrl,
    getLessonPending,
    getCurrentLessonSuccess,
    getLessonFail,
    getSyllabusPending,
    getSyllabusSuccess,
    getSyllabusFail,
    getQuestionPending,
    getQuestionSuccess,
    getQuestionFail,
    addQuizAttemptFail,
    setQuizAttempt,
    downloadMaterialFail,
} = ideSlice.actions;

export default ideSlice.reducer;
