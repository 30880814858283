import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

import config from "./config";

class FirebaseService {
    db: firebase.firestore.Firestore;

    auth: firebase.auth.Auth;

    storage: firebase.storage.Storage;

    functions: firebase.functions.Functions;

    googleAuthProvider: firebase.auth.GoogleAuthProvider;

    constructor() {
        if (
            Object.entries(config).length === 0 &&
            config.constructor === Object
        ) {
            if (process.env.REACT_APP_NODE_ENV === "development") {
                console.warn("Missing Firebase Configuration");
            }
        }
        if (!firebase.apps.length) {
            firebase.initializeApp(config);

            this.db = firebase.firestore();
            this.auth = firebase.auth();
            this.storage = firebase.storage();
            this.googleAuthProvider = new firebase.auth.GoogleAuthProvider();
            this.functions = firebase.functions();
        }
    }
}

const instance = new FirebaseService();

export default instance;
