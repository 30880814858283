/* eslint-disable @typescript-eslint/no-floating-promises */
import firebase from "firebase/app";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { IProfile, IProfileBase } from "src/@types/user";
import { ICourse } from "src/@types/course";
import { StringMap } from "i18next";
import { hasKey } from "@doar/shared/methods";
import firebaseService from "../service/service";
import type { AppDispatch } from "../store/store";

export const getProfile = (username: string) => {
    return new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            reject(err);
        }

        firebaseService.db
            .collection("users")
            .where("username", "==", username)
            .get()
            .then((querySnapshot) => {
                const profiles: IProfile[] = [];
                querySnapshot.forEach((doc) => {
                    profiles.push({
                        id: doc.id,
                        username: doc.data()?.username,
                        name: doc.data()?.name,
                        orders: doc.data()?.orders,
                        courses: doc.data()?.courses,
                        email: doc.data()?.email,
                        emailVerified: doc.data()?.emailVerified,
                        quizzes: doc.data()?.quizzes,
                        progresses: doc.data()?.progresses,
                        groups: doc.data()?.groups,
                        personal_data: doc.data()?.personal_data,
                        phone: doc.data()?.phone,
                        telegram: doc.data()?.telegram,
                        whatsapp: doc.data()?.whatsapp,
                        surveys: doc.data()?.surveys,
                        subscriptions: doc.data()?.subscriptions,
                        avatar64: doc.data()?.avatar64,
                    });
                });
                resolve(profiles);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getMyProfile = (email: string) => {
    return new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            reject(err);
        }

        firebaseService.db
            .collection("users")
            .doc(email)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    if (doc.data()) {
                        const myProfile: IProfile = {
                            id: doc.id,
                            username: doc.data()?.username,
                            name: doc.data()?.name,
                            orders: doc.data()?.orders,
                            courses: doc.data()?.courses,
                            email: doc.data()?.email,
                            emailVerified: doc.data()?.emailVerified,
                            quizzes: doc.data()?.quizzes,
                            progresses: doc.data()?.progresses,
                            groups: doc.data()?.groups,
                            personal_data: doc.data()?.personal_data,
                            phone: doc.data()?.phone,
                            telegram: doc.data()?.telegram,
                            whatsapp: doc.data()?.whatsapp,
                            surveys: doc.data()?.surveys,
                            balance: doc.data()?.balance,
                            favoriteMentors: doc.data()?.favoriteMentors,
                            favoriteLectures: doc.data()?.favoriteLectures,
                            subscriptions: doc.data()?.subscriptions,
                            avatar64: doc.data()?.avatar64,
                        };
                        resolve(myProfile);
                    } else {
                        const error = new Error("No user data");
                        reject(error);
                    }
                } else {
                    const error = new Error("No such user");
                    reject(error);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const subscribeToMyProfile = (
    dispatch: AppDispatch,
    email: string,
    getMyProfileSuccess: ActionCreatorWithPayload<
        { myProfile: IProfile },
        string
    >,
    getMyProfileFail: ActionCreatorWithPayload<{ error: string }, string>
): Promise<firebase.Unsubscribe> => {
    return new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            reject(err);
        }

        const unsubscribe = firebaseService.db.doc(`users/${email}`).onSnapshot(
            {
                includeMetadataChanges: true,
            },
            (doc) => {
                if (doc.data()) {
                    const myProfile: IProfile = {
                        id: doc.id,
                        username: doc.data()?.username,
                        name: doc.data()?.name,
                        orders: doc.data()?.orders,
                        courses: doc.data()?.courses,
                        email: doc.data()?.email,
                        emailVerified: doc.data()?.emailVerified,
                        quizzes: doc.data()?.quizzes,
                        progresses: doc.data()?.progresses,
                        groups: doc.data()?.groups,
                        personal_data: doc.data()?.personal_data,
                        phone: doc.data()?.phone,
                        telegram: doc.data()?.telegram,
                        whatsapp: doc.data()?.whatsapp,
                        surveys: doc.data()?.surveys,
                        balance: doc.data()?.balance,
                        favoriteMentors: doc.data()?.favoriteMentors,
                        favoriteLectures: doc.data()?.favoriteLectures,
                        subscriptions: doc.data()?.subscriptions,
                        avatar64: doc.data()?.avatar64,
                    };
                    dispatch(getMyProfileSuccess({ myProfile }));
                } else {
                    const err = new Error("No data");
                    dispatch(getMyProfileFail({ error: err.message }));
                }
            },
            (error) => {
                dispatch(getMyProfileFail({ error: error.message }));
            }
        );

        resolve(unsubscribe);
    });
};

export const updateCourseProgress = (
    email: string,
    nextLesson: string,
    courseSlug: string
) => {
    return new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            reject(err);
        }

        const profileRef = firebaseService.db.collection("users").doc(email);

        profileRef
            .update({
                [`progresses.${courseSlug}.lesson`]: nextLesson,
            })
            .then(() => {
                resolve(true);
            })
            .catch((error) => {
                console.error(error.message);
                reject(error);
            });
    });
};

export const updateTaskProgress = (
    email: string,
    taskId: string,
    courseSlug: string
): Promise<any> => {
    return new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            reject(err);
        }

        const profileRef = firebaseService.db
            .collection("users")
            .doc(email)
            .collection("progresses")
            .doc(courseSlug);
        profileRef
            .set(
                {
                    success: {
                        [taskId]: true,
                    },
                },
                { merge: true }
            )
            .then(() => {
                resolve("success");
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const removeSkipped = (
    email: string,
    activeLessonId: string,
    courseSlug: string
) => {
    return new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            reject(err);
        }

        const profileRef = firebaseService.db.collection("users").doc(email);

        profileRef
            .update({
                [`progresses.${courseSlug}.skipped.${activeLessonId}`]:
                    firebase.firestore.FieldValue.delete(),
            })
            .then(() => {
                resolve(true);
            })
            .catch((error) => {
                console.error(error.message);
                reject(error);
            });
    });
};

export const skipTask = (
    email: string,
    lessonId: string,
    courseSlug: string,
    nextLesson: string
) => {
    return new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            reject(err);
        }

        const profileRef = firebaseService.db.collection("users").doc(email);

        profileRef
            .update({
                [`progresses.${courseSlug}.skipped.${lessonId}`]: true,
                [`progresses.${courseSlug}.lesson`]: nextLesson,
            })
            .then(() => {
                resolve(true);
            })
            .catch((error) => {
                console.error(error.message);
                reject(error);
            });
    });
};

export const updateProfileSurveys = (
    email: string,
    surveyId: string,
    weekNum: number
) => {
    return new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            reject(err);
        }
        const profileRef = firebaseService.db.collection("users").doc(email);

        profileRef
            .update({
                [`surveys.${surveyId}`]: weekNum,
            })
            .then(() => {
                resolve(true);
            })
            .catch((error) => {
                console.error(error.message);
                reject(error);
            });
    });
};

export const subscribeToHouse = (subscriber: IProfileBase, houseId: string) => {
    return new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            reject(err);
        }

        async function update() {
            console.log("Inside update", subscriber);
            const wallUsers = firebaseService.db
                .collection(`houses/${houseId}/users`)
                .doc(`${String(subscriber.id)}`);
            const user = firebaseService.db
                .collection("users")
                .doc(`${String(subscriber.data.email)}`);
            const houseRef = firebaseService.db.doc(`houses/${houseId}`);
            await firebaseService.db
                .doc(`houses/${houseId}`)
                .get()
                .then((doc) => {
                    try {
                        const batch = firebaseService.db.batch();
                        batch.update(user, {
                            [`subscriptions.${houseId}`]: true,
                        });
                        batch.set(wallUsers, {
                            ...subscriber.data,
                        });
                        batch.update(houseRef, {
                            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                            users: doc.data()?.users + 1,
                        });
                        batch
                            .commit()
                            .then(async () => {
                                await getMyProfile(subscriber.data.email)
                                    .then((res: any) => {
                                        const { subscriptions } = res;
                                        console.log(subscriptions);
                                        resolve(subscriptions);
                                    })
                                    .catch((err) => {
                                        reject(err);
                                    });
                            })
                            .catch((err) => {
                                reject(err);
                            });

                        // await firebaseService.db.runTransaction(async (t) => {
                        //     await t.get(user);
                        //     t.update(user, {
                        //         [`subscriptions.${houseId}`]: false
                        //     });
                        //     t.delete(wallUsers);

                        // })
                        // .then((myProfile: any)=>{
                        //     getMyProfile(email).then((res:any)=>{
                        //         const {subscriptions} = res;
                        //         resolve(subscriptions);
                        //     }).catch((err)=>{
                        //         reject(err);
                        //     })
                        // })
                        // .catch(error=>{
                        //     reject(error);
                        // })
                    } catch (error) {
                        reject(error);
                    }
                });
        }

        update();
    });
};

// create a function to unsubscribe to the house
export const unsubscribeToHouse = (
    subscriber: IProfileBase,
    houseId: string
) => {
    return new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            reject(err);
        }

        async function update() {
            const wallUsers = firebaseService.db
                .collection(`houses/${houseId}/users`)
                .doc(`${String(subscriber.id)}`);
            const user = firebaseService.db
                .collection("users")
                .doc(`${String(subscriber.data.email)}`);
            const houseRef = firebaseService.db.doc(`houses/${houseId}`);
            await firebaseService.db
                .doc(`houses/${houseId}`)
                .get()
                .then((doc) => {
                    try {
                        const batch = firebaseService.db.batch();
                        batch.update(user, {
                            [`subscriptions.${houseId}`]: false,
                        });
                        batch.delete(wallUsers);
                        batch.update(houseRef, {
                            users: doc.data()?.users - 1,
                        });
                        batch
                            .commit()
                            .then(() => {
                                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                                getMyProfile(subscriber.data.email)
                                    .then((res: any) => {
                                        const { subscriptions } = res;
                                        resolve(subscriptions);
                                    })
                                    .catch((err) => {
                                        reject(err);
                                    });
                            })
                            .catch((err) => {
                                reject(err);
                            });

                        // await firebaseService.db.runTransaction(async (t) => {
                        //     await t.get(user);
                        //     t.update(user, {
                        //         [`subscriptions.${houseId}`]: false
                        //     });
                        //     t.delete(wallUsers);

                        // })
                        // .then((myProfile: any)=>{
                        //     getMyProfile(email).then((res:any)=>{
                        //         const {subscriptions} = res;
                        //         resolve(subscriptions);
                        //     }).catch((err)=>{
                        //         reject(err);
                        //     })
                        // })
                        // .catch(error=>{
                        //     reject(error);
                        // })
                    } catch (error) {
                        reject(error);
                    }
                });
        }
        update();
    });
};

export const updateProfileProjectApi = (
    email: string,
    project: any,
    projectsToAppend: any
) => {
    return new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            reject(err);
        }

        firebaseService.db
            .collection("users")
            .doc(email)
            .set(
                {
                    projects: {
                        ...projectsToAppend,
                        [project.projectId]: {
                            ...project,
                            // finishedAt: project.isActive? null : firebase.firestore.Timestamp.now(),
                            // startedAt: project.isActive? firebase.firestore.Timestamp.now():null
                        },
                    },
                },
                { merge: true }
            )
            .then(() => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                getMyProfile(email)
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const updateProfileFavoritesApi = (
    email: string,
    favoriteItem: Record<string, any>,
    type: string
) => {
    return new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            reject(err);
        }

        firebaseService.db
            .collection("users")
            .doc(email)
            .set(
                {
                    favorites: {
                        [favoriteItem.id]: {
                            ...favoriteItem,
                            type,
                        },
                    },
                },
                { merge: true }
            )
            .then(() => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                getMyProfile(email)
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getMyProfileProgressApi = (email: string, courseSlug: string) => {
    return new Promise((resolve, reject) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            reject(err);
        }
        console.log("INSIDE API", email, courseSlug);
        firebaseService.db
            .collection("users")
            .doc(email)
            .collection("progresses")
            .doc(courseSlug)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    const { success } = doc.data() as any;
                    if (success) {
                        resolve(success);
                    }
                    resolve({});
                }
                resolve({});
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const updateProfilePhotoAndUsername = ({
    photo,
    username,
}: {
    photo: File;
    username: string;
}): Promise<string | Error> =>
    new Promise((res, rej) => {
        if (!firebase.apps.length) {
            const err = new Error(
                "Firebase Service didn't initialize, check your configuration"
            );
            rej(err);
        }

        /// store user image in storage and get permanent url
        if (
            firebaseService.auth.currentUser !== null &&
            "email" in firebaseService.auth.currentUser
        ) {
            const user = firebaseService.auth.currentUser;
            const { email } = user;
            const storageRef = firebaseService.storage.ref();
            const imageRef = storageRef.child(
                `profile-avatars/${username}.jpeg`
            );
            imageRef
                .put(photo)
                .then(async (snapshot) => {
                    const url = await snapshot.ref.getDownloadURL();
                    /// update user profile
                    await firebaseService.db
                        .collection("users")
                        .doc(String(email))
                        .set(
                            {
                                avatar: url,
                                username,
                            },
                            { merge: true }
                        );
                    firebaseService.auth.currentUser
                        ?.updateProfile({
                            photoURL: url,
                            displayName: username,
                        })
                        .then(() => {
                            res("OK");
                        })
                        .catch((err) => {
                            rej(err);
                        });
                })
                .catch((err) => {
                    rej(err);
                });
        } else {
            rej(new Error("User not logged in"));
        }
    });
