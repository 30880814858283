import styled, { css } from "@doar/shared/styled";
import { Tabs } from "react-tabs";

interface ITab {
    $justified?: boolean;
    $vertical?: boolean;
    $variation?: "line" | "scroll";
    $scrollable?: boolean;
    $hideScroll?: boolean;
}

export const StyledTabs = styled(({ ...rest }) => <Tabs {...rest} />)<ITab>`
    .react-tabs {
        &__tab-list {
            ${({ $hideScroll }) =>
                $hideScroll === true &&
                css`
                    ::-webkit-scrollbar {
                        width: 0px;
                        height: 0px;
                        display: none;
                    }
                `}
            display: flex;
            flex-wrap: wrap;
            border-color: rgba(72, 94, 144, 0.16);
            ${({ $vertical }) =>
                $vertical === true &&
                css`
                    border-bottom: 0;
                    border-right: 0;
                    justify-content: flex-start;
                    flex-direction: column;
                    width: 150px;
                `}
            ${({ $variation }) =>
                $variation === "line" &&
                css`
                    margin-top: 20px;
                    border-top: 0.5px solid #e2e5ed;
                    border-bottom: 0.5px solid rgba(72, 94, 144, 0.16);
                    border-left: 0.5px solid rgba(72, 94, 144, 0.16) !important;
                `}
                ${({ $variation }) =>
                $variation === "scroll" &&
                css`
                    /* max-width: 440px; */
                    overflow-x: scroll;
                    display: flex !important;
                    flex-wrap: nowrap;
                    height: 55.5px !important;
                    margin-top: 20px;
                    border-top: 0.5px solid #e2e5ed;
                    border-bottom: 0.5px solid rgba(72, 94, 144, 0.16);
                    border-left: 0.5px solid rgba(72, 94, 144, 0.16);
                `}
                ${({ $scrollable }) =>
                !!$scrollable &&
                css`
                    overflow-x: scroll;
                    height: 60.5px;
                    /* max-width: 440px; */
                `}
        }
        &__tab {
            margin-bottom: 0;
            background-color: rgba(72, 94, 144, 0.16);
            border-color: rgba(72, 94, 144, 0.16);
            color: black;
            border-bottom-width: 0;
            position: relative;
            margin-bottom: 13px;
            margin-top: 13px;
            padding: 0 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            text-transform: capitalize;
            font-weight: 500px;
            height: 28px;
            transition: background-color 0.2s;
            border: 1px solid transparent;
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem;
            cursor: pointer;
            &:not(:first-of-type) {
                margin-left: 5px;
            }
            &--selected {
                padding: 5px 10px;
                height: 28px;
                box-sizing: border-box;
                color: black;
                background: linear-gradient(
                    260.89deg,
                    #faeac8 4.33%,
                    #faedd5 31.9%,
                    #f9efe1 60.81%,
                    #ecd7d1 99.18%
                );
                z-index: 5;
                border-radius: 14px;

                border-color: #cdd4e0 #cdd4e0 #fff;
            }
            &--disabled {
                background-color: transparent;
                color: #b4bdce;
                border-color: transparent;
                pointer-events: none;
                cursor: default;
            }
            ${({ $justified }) =>
                $justified === true &&
                css`
                    flex-basis: 0;
                    flex-grow: 1;
                    text-align: center;
                `}
            ${({ $vertical }) =>
                $vertical === true &&
                css`
                    border-color: rgba(72, 94, 144, 0.16);
                    border-right-width: 0;
                    margin-bottom: 0;
                    margin-right: -1px;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border-top-left-radius: 0.25rem;
                    border-bottom-left-radius: 0.25rem;
                    &:not(:first-of-type) {
                        margin-left: 0;
                        margin-top: 5px;
                    }
                    &--selected {
                        border-color: rgba(72, 94, 144, 0.16);
                    }
                `}
			${({ $variation }) =>
                $variation === "line" &&
                css`
                    padding: 5px 10px !important;
                    background-color: transparent;
                    height: 28px !important;
                    font-size: 14px !important;
                    border: none;
                    &:not(:first-of-type) {
                        margin-left: 25px;
                    }
                    &--selected {
                        border: none;
                        &:after {
                            content: "";
                            position: absolute;
                            bottom: -1px;
                            left: 0;
                            right: 0;
                            height: 28px !important;
                            overflow: hidden;
                        }
                    }
                `}
                ${({ $variation }) =>
                $variation === "scroll" &&
                css`
                    padding: 5px 10px !important;
                    background-color: transparent;
                    height: 28px !important;
                    font-size: 14px !important;
                    border: none;
                    &:not(:first-of-type) {
                        margin-left: 25px;
                    }
                    &--selected {
                        border: none;
                        &:after {
                            content: "";
                            position: absolute;
                            bottom: -1px;
                            left: 0;
                            right: 0;
                            height: 28px !important;
                            overflow: hidden;
                        }
                    }
                `}
        }
        &__tab-panel {
            transition: all 0.15s linear;
            display: none;
            height: 0;
            max-width: 100%;
            visibility: hidden;
            overflow: hidden;
            opacity: 0;
            &--selected {
                display: block;
                height: auto;
                visibility: visible;
                opacity: 1;
                overflow: visible;
            }
        }
        &__content {
            ${({ $vertical }) =>
                $vertical === true &&
                css`
                    flex: 1;
                `}
            ${({ $variation }) =>
                $variation === "line" &&
                css`
                    /* border: none; */
                    border-left: 0.5px solid rgba(72, 94, 144, 0.16);
                    padding-bottom: 100px;
                `}
        }
    }
`;

export const StyledTabContent = styled.div`
    padding: 20px;
    border: 1px solid #cdd4e0;
    border: none;
`;
