import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompanyProps } from "src/@types/company";

export enum CompaniesStatus {
    Loaded,
    Loading,
    Unloaded,
}

export enum CompanyStatus {
    Loaded,
    Loading,
    Unloaded,
}

export enum CompanyWallStatus {
    Loaded,
    Loading,
    Unloaded,
}

export enum CompanySubscribersStatus {
    Loaded,
    Loading,
    Unloaded,
}

export interface CompaniesState {
    areLoaded: CompaniesStatus;
    error: string;
    companies: any[];
    mentors: string[];
    allCompanies: any[];
    filter: string;
    subject: string;
    filtered: boolean;
    company: any;
    lastWallId: number;
    isLoaded: CompanyStatus;
    mentor: any;
    subscription: any;
    stackList: string[];
    levelsList: string[];
    wallIsLoaded: CompanyWallStatus;
    companySubscribers: any[];
    companySubscribersLoaded: CompanySubscribersStatus;
}

const initialState: CompaniesState = {
    areLoaded: CompaniesStatus.Loading,
    error: "",
    companies: [],
    mentors: [],
    stackList: [],
    levelsList: [],
    allCompanies: [],
    filter: "all",
    subject: "all",
    filtered: false,
    company: {},
    mentor: {},
    subscription: {},
    isLoaded: CompanyStatus.Loading,
    wallIsLoaded: CompanyWallStatus.Loading,
    lastWallId: 0,
    companySubscribers: [],
    companySubscribersLoaded: CompanySubscribersStatus.Loading,
};

const companiesSlice = createSlice({
    name: "companies",
    initialState,
    reducers: {
        companiesSuccess(
            state,
            action: PayloadAction<{
                companies: any[];
                // mentors: string[];
                // stackList: string[];
            }>
        ) {
            const {
                payload: { companies },
            } = action;
            state.areLoaded = CompaniesStatus.Loaded;
            state.companies = companies;
            state.allCompanies = companies;
            // state.mentors = mentors;
            // state.stackList = stackList;
            state.error = "";
        },
        companySuccess(
            state,
            action: PayloadAction<{
                company: any;
                mentor: any;
            }>
        ) {
            const {
                payload: { company, mentor },
            } = action;
            state.isLoaded = CompanyStatus.Loaded;
            state.company = company;
            state.error = "";
        },
        companyFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.isLoaded = CompanyStatus.Unloaded;
            state.error = error;
        },
        companiesFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.areLoaded = CompaniesStatus.Unloaded;
            state.error = error;
        },

        filterApplied(
            state,
            action: PayloadAction<{
                filtered: boolean;
            }>
        ) {
            const {
                payload: { filtered },
            } = action;
            state.filtered = filtered;
        },
        filterChanged(
            state,
            action: PayloadAction<{
                filter: string;
            }>
        ) {
            const {
                payload: { filter },
            } = action;
            state.filter = filter;
        },
        subjectChanged(
            state,
            action: PayloadAction<{
                subject: string;
            }>
        ) {
            const {
                payload: { subject },
            } = action;
            state.subject = subject;
        },
        companiesFiltered(
            state,
            action: PayloadAction<{
                companies: any[];
            }>
        ) {
            const {
                payload: { companies },
            } = action;
            state.companies = companies;
        },
    },
});

export const {
    companiesSuccess,
    companiesFail,
    filterChanged,
    subjectChanged,
    companiesFiltered,
    filterApplied,
    companyFail,
    companySuccess,
} = companiesSlice.actions;
export default companiesSlice.reducer;
