import { IProfile, IProfileBase } from "src/@types/user";
import firebase from "firebase";
import {
    getProfilePending,
    getProfileSuccess,
    getProfileFail,
    getMyProfileSuccess,
    getMyProfileFail,
    getSubscriptionFail,
    getSubscriptionPending,
    getSubscriptionSuccess,
    getMyProfileProgressessSuccess,
} from "../slices/profile";

import { favoriteMentorsUpdateSuccess } from "../slices/mentors";
import { favoriteLecturesUpdateSuccess } from "../slices/meetups";

import {
    getProfile,
    getMyProfile,
    subscribeToMyProfile,
    updateProfileSurveys,
    subscribeToHouse,
    unsubscribeToHouse,
    updateProfileProjectApi,
    updateProfileFavoritesApi,
    getMyProfileProgressApi,
} from "../../api/profile";

import type { AppDispatch } from "../store";
import { HouseSubscribers } from "./housesActions";

export const Profile =
    (username: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            dispatch(getProfilePending());
            await getProfile(username).then(
                (result) => {
                    const profile = result as IProfile[];
                    if (profile.length === 0) {
                        dispatch(
                            getProfileFail({
                                error: "Profile with this username not found",
                            })
                        );
                    } else {
                        dispatch(getProfileSuccess({ profile: profile[0] }));
                    }
                },
                (error) => {
                    dispatch(getProfileFail({ error: error.message }));
                }
            );
        } catch (error) {
            const { message } = error as Error;
            dispatch(getProfileFail({ error: message }));
        }
    };

export const MyProfile =
    (email: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            await getMyProfile(email).then(
                (result) => {
                    const profile = result as IProfile;
                    const { favoriteMentors, favoriteLectures } = profile;
                    dispatch(
                        favoriteMentorsUpdateSuccess({
                            favs: favoriteMentors || {},
                        })
                    );
                    dispatch(
                        favoriteLecturesUpdateSuccess({
                            favs: favoriteLectures || {},
                        })
                    );
                    dispatch(getMyProfileSuccess({ myProfile: profile }));
                },
                (error) => {
                    dispatch(getMyProfileFail({ error: error.message }));
                }
            );
        } catch (error) {
            const { message } = error as Error;
            dispatch(getMyProfileFail({ error: message }));
        }
    };

export const UpdateProfileSurveys = (
    email: string,
    surveyId: string,
    weekNum: number
) => {
    return async (dispatch: AppDispatch) => {
        try {
            await updateProfileSurveys(email, surveyId, weekNum).then(
                (result) => {
                    const profile = result as IProfile;
                    dispatch(getMyProfileSuccess({ myProfile: profile }));
                }
            );
        } catch (error) {
            dispatch(getMyProfileFail({ error: error.message }));
        }
    };
};

export const SubscribeToMyProfile =
    (email: string) =>
    (dispatch: AppDispatch): Promise<firebase.Unsubscribe | null> | null => {
        try {
            return subscribeToMyProfile(
                dispatch,
                email,
                getMyProfileSuccess,
                getMyProfileFail
            ).then(
                (result) => {
                    return result;
                },
                (error) => {
                    dispatch(getMyProfileFail({ error: error.message }));
                    return null;
                }
            );
        } catch (error) {
            const { message } = error as Error;
            dispatch(getMyProfileFail({ error: message }));
            return null;
        }
    };

export const SubscribeToHouse = (subscriber: IProfileBase, houseId: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(getSubscriptionPending());
            await subscribeToHouse(subscriber, houseId).then(
                (result) => {
                    const subs = result as Record<string, any>;
                    console.log(subs);
                    dispatch(getSubscriptionSuccess({ subscriptions: subs }));
                },
                (error) => {
                    dispatch(getSubscriptionFail({ error: error.message }));
                }
            );
            await dispatch(HouseSubscribers(houseId));
        } catch (error) {
            dispatch(getSubscriptionFail({ error: error.message }));
        }
    };
};

export const UnsubscribeFromHouse = (
    subscriber: IProfileBase,
    houseId: string
) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(getSubscriptionPending());
            await unsubscribeToHouse(subscriber, houseId).then(
                (result) => {
                    const subs = result as Record<string, any>;
                    dispatch(getSubscriptionSuccess({ subscriptions: subs }));
                },
                (error) => {
                    dispatch(getSubscriptionFail({ error: error.message }));
                }
            );
            await dispatch(HouseSubscribers(houseId));
        } catch (error) {
            dispatch(getSubscriptionFail({ error: error.message }));
        }
    };
};

export const UpdateProfileProject =
    (email: string, project: any, projectsToAppend: any) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            await updateProfileProjectApi(
                email,
                project,
                projectsToAppend
            ).then(
                (result) => {
                    const profile = result as IProfile;

                    dispatch(
                        getMyProfileSuccess({
                            myProfile: profile,
                        })
                    );
                },
                (error) => {
                    dispatch(getMyProfileFail({ error: error.message }));
                }
            );
        } catch (error) {
            dispatch(getMyProfileFail({ error: error.message }));
        }
    };

export const UpdateProfileFavorites = (
    userId: string,
    favorite: Record<string, any>,
    type: string
) => {
    return async (dispatch: AppDispatch) => {
        try {
            await updateProfileFavoritesApi(userId, favorite, type).then(
                (result) => {
                    const profile = result as IProfile;
                    dispatch(getMyProfileSuccess({ myProfile: profile }));
                }
            );
        } catch (error) {
            dispatch(getMyProfileFail({ error: error.message }));
        }
    };
};

export const GetMyProfileProgress = (email: string, courseSlug: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            await getMyProfileProgressApi(email, courseSlug).then((result) => {
                const progresses = result as Record<string, any>;
                dispatch(getMyProfileProgressessSuccess({ progresses }));
            });
        } catch (error) {
            dispatch(getMyProfileFail({ error: error.message }));
        }
    };
};
