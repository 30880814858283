import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./translations/en.json";
import ru from "./translations/ru.json";

const resources = {
    en,
    ru,
};

export const availableLanguages = Object.keys(resources);

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n.use(initReactI18next).init({
    resources,
    defaultNS: "common",
    fallbackLng: "ru",
});

export default i18n;
