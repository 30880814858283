import {
    configureStore,
    combineReducers,
    getDefaultMiddleware,
} from "@reduxjs/toolkit";
import eventReducer from "./slices/event";
import meetupsReducer from "./slices/meetups";
import uiReducer from "./slices/ui";
import chatUISlice from "./slices/chat-ui";
import loginReducer from "./slices/login";
import signupReducer from "./slices/signup";
import userReducer from "./slices/user";
import careerReducer from "./slices/career";
import recentCoursesReducer from "./slices/recentCourses";
import coursesReducer from "./slices/courses";
import profileReducer from "./slices/profile";
import ideReducer from "./slices/ide";
import surveyReducer from "./slices/surveys";
import mentorsReducer from "./slices/mentors";
import housesReducer from "./slices/houses";
import projectsReducer from "./slices/projects";
import companiesReducer from "./slices/companies";
import staffReducer from "./slices/staff";
import solutionsReducer from "./slices/ide-task-solutions";

const rootReducer = combineReducers({
    events: eventReducer,
    meetups: meetupsReducer,
    ui: uiReducer,
    chatUI: chatUISlice,
    login: loginReducer,
    signup: signupReducer,
    user: userReducer,
    career: careerReducer,
    recentCourses: recentCoursesReducer,
    courses: coursesReducer,
    profile: profileReducer,
    ide: ideReducer,
    surveys: surveyReducer,
    mentors: mentorsReducer,
    houses: housesReducer,
    projects: projectsReducer,
    companies: companiesReducer,
    staff: staffReducer,
    solutions: solutionsReducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: () =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
