import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HouseWallProps } from "src/@types/house";
import { IProfileBase } from "src/@types/user";
import { toast } from "react-toastify";
import i18next from "../../i18n";

export enum HousesStatus {
    Loaded,
    Loading,
    Unloaded,
}

export enum HouseStatus {
    Loaded,
    Loading,
    Unloaded,
}

export enum HouseWallStatus {
    Loaded,
    Loading,
    Unloaded,
}

export enum HouseSubscribersStatus {
    Loaded,
    Loading,
    Unloaded,
}

export enum HouseWallCommentStatus {
    Loaded,
    Loading,
    Unloaded,
}

export interface HousesState {
    areLoaded: HousesStatus;
    error: string;
    houses: any[];
    mentors: string[];
    allHouses: any[];
    filter: string;
    subject: string;
    filtered: boolean;
    house: any;
    houseWall: HouseWallProps[];
    lastWallId: number;
    isLoaded: HouseStatus;
    mentor: any;
    subscription: any;
    stackList: string[];
    levelsList: string[];
    wallIsLoaded: boolean;
    houseSubscribers: IProfileBase[];
    houseSubscribersLoaded: HouseSubscribersStatus;
    houseWallVoteLoading: boolean;
    houseWallPostLoading: boolean;
    houseWallPost: HouseWallProps | null;
    houseWallPostError: any;
    wallCommentsUpdateLoading: boolean;
    wallCommentsUpdateError: any;
}

const initialState: HousesState = {
    areLoaded: HousesStatus.Loading,
    error: "",
    houses: [],
    mentors: [],
    stackList: [],
    levelsList: [],
    allHouses: [],
    filter: "all",
    subject: "all",
    filtered: false,
    house: {},
    mentor: {},
    subscription: {},
    isLoaded: HouseStatus.Loading,
    houseWall: [],
    wallIsLoaded: false,
    lastWallId: 0,
    houseSubscribers: [],
    houseSubscribersLoaded: HouseSubscribersStatus.Loading,
    houseWallVoteLoading: false,
    houseWallPostLoading: false,
    houseWallPost: null,
    houseWallPostError: null,
    wallCommentsUpdateLoading: false,
    wallCommentsUpdateError: null,
};

const housesSlice = createSlice({
    name: "houses",
    initialState,
    reducers: {
        housesSuccess(
            state,
            action: PayloadAction<{
                houses: any[];
            }>
        ) {
            const {
                payload: { houses },
            } = action;
            state.areLoaded = HousesStatus.Loaded;
            state.houses = houses;
            state.allHouses = houses;
            state.error = "";
        },
        houseSuccess(
            state,
            action: PayloadAction<{
                house: any;
                mentor: any;
            }>
        ) {
            const {
                payload: { house, mentor },
            } = action;
            state.isLoaded = HouseStatus.Loaded;
            state.house = house;
            state.mentor = mentor;
            state.error = "";
        },
        houseFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.isLoaded = HouseStatus.Unloaded;
            state.error = error;
        },
        housesFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.areLoaded = HousesStatus.Unloaded;
            state.error = error;
        },
        houseWallLoading(state) {
            state.wallIsLoaded = false;
        },
        houseWallSuccess(
            state,
            action: PayloadAction<{
                houseWall: HouseWallProps[];
                lastWallId: number;
            }>
        ) {
            const {
                payload: { houseWall, lastWallId },
            } = action;

            state.houseWall = houseWall;
            state.houseWallVoteLoading = false;
            state.lastWallId = lastWallId;
            state.wallIsLoaded = true;
            state.error = "";
        },
        houseWallNextSuccess(
            state,
            action: PayloadAction<{
                houseWall: HouseWallProps[];
                lastWallId: number;
            }>
        ) {
            const {
                payload: { houseWall, lastWallId },
            } = action;
            state.houseWall = [...state.houseWall, ...houseWall];
            state.lastWallId = lastWallId;
            state.houseWallVoteLoading = false;
            state.wallIsLoaded = true;
            state.error = "";
        },

        houseWallFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.error = error;
            state.wallIsLoaded = true;
        },
        houseSubscribersSuccess(
            state,
            action: PayloadAction<{
                houseSubscribers: IProfileBase[];
            }>
        ) {
            const {
                payload: { houseSubscribers },
            } = action;
            state.houseSubscribers = houseSubscribers;
            state.houseSubscribersLoaded = HouseSubscribersStatus.Loaded;
            state.error = "";
        },
        houseSubscribersFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.error = error;
            state.houseSubscribersLoaded = HouseSubscribersStatus.Unloaded;
        },
        filterApplied(
            state,
            action: PayloadAction<{
                filtered: boolean;
            }>
        ) {
            const {
                payload: { filtered },
            } = action;
            state.filtered = filtered;
        },
        filterChanged(
            state,
            action: PayloadAction<{
                filter: string;
            }>
        ) {
            const {
                payload: { filter },
            } = action;
            state.filter = filter;
        },
        subjectChanged(
            state,
            action: PayloadAction<{
                subject: string;
            }>
        ) {
            const {
                payload: { subject },
            } = action;
            state.subject = subject;
        },
        housesFiltered(
            state,
            action: PayloadAction<{
                houses: any[];
            }>
        ) {
            const {
                payload: { houses },
            } = action;
            state.houses = houses;
        },
        houseWallUpvoteSuccess(
            state,
            action: PayloadAction<{
                wallId: string;
                userId: string;
            }>
        ) {
            const {
                payload: { wallId, userId },
            } = action;
            const wall = state.houseWall.find((wallI) => wallI.id === wallId);
            if (wall) {
                wall.data.upvotes?.push(userId);
                wall.data.downvotes = wall.data.downvotes?.filter(
                    (id: string) => id !== userId
                );
            }
            if (state.houseWallPost && "data" in state.houseWallPost) {
                state.houseWallPost.data.upvotes?.push(userId);
                state.houseWallPost.data.downvotes =
                    state.houseWallPost.data.downvotes?.filter(
                        (id: string) => id !== userId
                    );
            }
            state.houseWallVoteLoading = false;
            toast.success(i18next.t("upvote-success"));
        },
        houseWallDownvoteSuccess(
            state,
            action: PayloadAction<{
                wallId: string;
                userId: string;
            }>
        ) {
            const {
                payload: { wallId, userId },
            } = action;
            const wall = state.houseWall.find((wallI) => wallI.id === wallId);
            if (wall) {
                wall.data.downvotes?.push(userId);
                wall.data.upvotes = wall.data.upvotes?.filter(
                    (id: string) => id !== userId
                );
            }
            if (state.houseWallPost && "data" in state.houseWallPost) {
                state.houseWallPost.data.downvotes?.push(userId);
                state.houseWallPost.data.upvotes =
                    state.houseWallPost.data.upvotes?.filter(
                        (id: string) => id !== userId
                    );
            }
            state.houseWallVoteLoading = false;
            toast.success(i18next.t("downvote-success"));
        },
        houseWallVoteLoading(state) {
            state.houseWallVoteLoading = true;
        },
        houseWallPostSuccess(
            state,
            action: PayloadAction<{
                wall: HouseWallProps;
            }>
        ) {
            const {
                payload: { wall },
            } = action;
            state.houseWall?.unshift(wall);
            state.houseWallPost = wall;
            state.houseWallPostLoading = false;
        },
        houseWallPostFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.houseWallPostError = error;
            state.houseWallPostLoading = false;
        },
        houseWallPostLoading(state) {
            state.houseWallPostLoading = true;
        },
        wallCommentsCountUpdate(
            state,
            action: PayloadAction<{
                wallId: string;
                increment: number;
            }>
        ) {
            const {
                payload: { wallId, increment },
            } = action;
            const wall = state.houseWall.find((wallI) => wallI.id === wallId);
            if (wall && wall.data.commentCount !== undefined) {
                wall.data.commentCount += increment;
            }
            if (state.houseWallPost) {
                if (
                    state.houseWallPost.id === wallId &&
                    state.houseWallPost.data.commentCount !== undefined
                ) {
                    state.houseWallPost.data.commentCount += increment;
                }
            }
        },
    },
});

export const {
    housesSuccess,
    housesFail,
    filterChanged,
    subjectChanged,
    housesFiltered,
    filterApplied,
    houseFail,
    houseSuccess,
    houseWallSuccess,
    houseWallFail,
    houseWallNextSuccess,
    houseSubscribersSuccess,
    houseSubscribersFail,
    houseWallUpvoteSuccess,
    houseWallDownvoteSuccess,
    houseWallVoteLoading,
    houseWallPostFail,
    houseWallPostLoading,
    houseWallPostSuccess,
    wallCommentsCountUpdate,
    houseWallLoading,
} = housesSlice.actions;
export default housesSlice.reducer;
