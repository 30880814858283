import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISurvey, ISurveyTypes } from "src/@types/survey";

export enum SurveysStatus {
    Loaded,
    Loading,
    Unloaded,
}

export interface SurveysState {
    areLoaded: SurveysStatus;
    areLoading: SurveysStatus;
    surveyError: string | null;
    surveysError: string | null;
    surveys: ISurvey[];
    survey: ISurvey | any;
    allSurveys: ISurvey[];
    isLoading: boolean;
    surveyAnswer: boolean;
    surveyTypes: ISurveyTypes[];
}

const initialState: SurveysState = {
    areLoaded: SurveysStatus.Loading,
    areLoading: SurveysStatus.Loading,
    surveyError: null,
    surveysError: null,
    survey: {},
    surveys: [],
    surveyAnswer: false,
    allSurveys: [],
    isLoading: true,
    surveyTypes: [],
};

const surveysSlice = createSlice({
    name: "surveys",
    initialState,
    reducers: {
        getSurveyPending(state) {
            state.isLoading = true;
        },
        surveySuccess(
            state,
            action: PayloadAction<{
                survey: ISurvey;
            }>
        ) {
            const {
                payload: { survey },
            } = action;
            state.isLoading = false;
            state.survey = survey;
            state.surveyError = null;
        },
        surveyAnswerSuccess(
            state,
            action: PayloadAction<{
                survey: boolean;
            }>
        ) {
            const {
                payload: { survey },
            } = action;
            state.isLoading = false;
            state.surveyAnswer = survey;
            state.surveyError = null;
        },
        surveyFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.isLoading = false;
            state.surveyError = error;
        },
        /// create a slice to get all surveys
        getAllSurveysPending(state) {
            state.isLoading = true;
        },
        getAllSurveysSuccess(
            state,
            action: PayloadAction<{
                surveys: ISurvey[];
                surveyTypes: ISurveyTypes[];
            }>
        ) {
            const {
                payload: { surveys, surveyTypes },
            } = action;
            state.areLoading = SurveysStatus.Loaded;
            state.surveys = surveys;
            state.surveyTypes = surveyTypes;
            state.surveysError = null;
        },
        getAllSurveysFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.areLoading = SurveysStatus.Unloaded;
            state.surveysError = error;
        },
    },
});

export const {
    surveySuccess,
    surveyAnswerSuccess,
    surveyFail,
    getSurveyPending,
    getAllSurveysPending,
    getAllSurveysSuccess,
    getAllSurveysFail,
} = surveysSlice.actions;
export default surveysSlice.reducer;
