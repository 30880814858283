import { css } from "@doar/shared/styled";
import ralewayBlack from "./Raleway/static/Raleway-Black.ttf";
import ralewayBold from "./Raleway/static/Raleway-Bold.ttf";
import ralewayMedium from "./Raleway/static/Raleway-Medium.ttf";
import ralewaySemiBold from "./Raleway/static/Raleway-SemiBold.ttf";
import ralewayThin from "./Raleway/static/Raleway-Thin.ttf";
import ralewayRegular from "./Raleway/static/Raleway-Regular.ttf";

export const RalewayFont = css`
    @font-face {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: local("Raleway Black"), local("Raleway-Black"),
            url(${ralewayBlack}) format("truetype");
    }
    @font-face {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: local("Raleway Bold"), local("Raleway-Bold"),
            url(${ralewayBold}) format("truetype");
    }
    @font-face {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: local("Raleway Medium"), local("Raleway-Medium"),
            url(${ralewayMedium}) format("truetype");
    }

    @font-face {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: local("Raleway SemiBold"), local("Raleway-SemiBold"),
            url(${ralewaySemiBold}) format("truetype");
    }
    @font-face {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: local("Raleway Regular"), local("Raleway-Regular"),
            url(${ralewayRegular}) format("truetype");
    }
`;
