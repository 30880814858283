import React, { Suspense, lazy, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { IBalance } from "src/@types/user";
import Preloader from "./components/preloader";
import { PrivateRoute } from "./utils/privateRoute";
import { updateBalance } from "./store/slices/profile";
import { useAppDispatch } from "./store/hooks";
import "react-reflex/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "react-modern-calendar-datepicker/lib/DatePicker.css";

const Courses = lazy(() => import("./apps/courses"));
const SignIn = lazy(() => import("./pages/signin"));
const SignUp = lazy(() => import("./pages/signup"));
const VerifyAccount = lazy(() => import("./pages/verify-account"));
const ForgotPassword = lazy(() => import("./pages/forgot-password"));
const ErrorNotFound = lazy(() => import("./pages/error-404"));
const Error401 = lazy(() => import("./pages/error-401"));
const Error500 = lazy(() => import("./pages/error-500"));
const Error503 = lazy(() => import("./pages/error-503"));
const Error505 = lazy(() => import("./pages/error-505"));
const Welcome = lazy(() => import("./pages/welcome"));
const WelcomeBack = lazy(() => import("./pages/welcome-back"));
const Profile = lazy(() => import("./pages/profile"));
const MyCourses = lazy(() => import("./apps/myCourses"));
const Calendar = lazy(() => import("./apps/calendar"));
// const MentorMeetups = lazy(() => import("./apps/mentor-meetups"));
const IDE = lazy(() => import("./apps/ide"));
// const Mentors = lazy(() => import("./apps/mentors"));
// const Events = lazy(() => import("./apps/lectures"));
// const MentorProfile = lazy(() => import("./pages/mentor-profile"));
const HouseView = lazy(() => import("./apps/house-view"));
const Houses = lazy(() => import("./pages/houses"));
const Companies = lazy(() => import("./pages/companies"));
const Company = lazy(() => import("./apps/company-detail"));
const Projects = lazy(() => import("./apps/projects"));
const ProjectDetails = lazy(() => import("./apps/project-detail"));
const HouseWallDetailView = lazy(() => import("./apps/house-detail-view"));
const HousesRedirector = lazy(
    () => import("./containers/houses/main/redirector")
);
const CoursesDetails = lazy(() => import("./apps/courses-detail"));
const App: React.FC = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (process.env.REACT_APP_NODE_ENV === "production") {
            const balance: IBalance = {
                761559: 17,
                761560: 45,
                761561: 90,
                761563: 180,
            };
            window.Paddle.Setup({
                vendor: 118527,
                eventCallback: (data: Record<string, any>) => {
                    console.log(data);
                    if (data.event === "Checkout.PaymentComplete") {
                        const { id } = data.eventData.product;
                        if (
                            id === 761559 ||
                            id === 761560 ||
                            id === 761561 ||
                            id === 761563
                        ) {
                            const payment: number = id;
                            dispatch(
                                updateBalance({ payment: balance[payment] })
                            );
                        }
                    }
                },
            });
        } else if (process.env.REACT_APP_NODE_ENV === "development") {
            const balance: IBalance = {
                9955: 47,
            };
            window.Paddle.Environment.set("sandbox");
            window.Paddle.Setup({
                vendor: 1534,
                eventCallback: (data: Record<string, any>) => {
                    console.log(data);
                    if (data.event === "Checkout.PaymentComplete") {
                        const { id } = data.eventData.product;
                        if (id === 9955) {
                            const payment: number = id;
                            dispatch(
                                updateBalance({ payment: balance[payment] })
                            );
                        }
                    }
                },
            });
        }
    }, []);
    const [language, setLanguage] = useState(
        localStorage.getItem("language") || "ru"
    );
    const { i18n } = useTranslation();
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        i18n.changeLanguage(language);
        localStorage.setItem("language", language);
    }, [language]);
    return (
        <Router>
            <Suspense fallback={<Preloader />}>
                <Switch>
                    <PrivateRoute exact path="/" component={HousesRedirector} />
                    <PrivateRoute
                        exact
                        path="/my-courses"
                        component={MyCourses}
                    />
                    <PrivateRoute exact path="/welcome" component={Welcome} />
                    <PrivateRoute exact path="/courses" component={Courses} />
                    <PrivateRoute
                        exact
                        path="/courses/:courseId"
                        component={CoursesDetails}
                    />
                    {/* <PrivateRoute exact path="/mentors" component={Mentors} />
                    <PrivateRoute exact path="/events" component={Events} />
                    <PrivateRoute
                        exact
                        path="/mentors/:username"
                        component={MentorProfile}
                    />
                    <PrivateRoute
                        exact
                        path="/mentors/:username/meetups"
                        component={MentorMeetups}
                    /> */}
                    {/* <PrivateRoute
                        exact
                        path="/dashboard"
                        component={DashboardOne}
                    /> */}
                    {/* <PrivateRoute exact path="/chat" component={Chat} /> */}
                    <PrivateRoute
                        exact
                        path="/ide/:course/:less?"
                        component={IDE}
                    />
                    <PrivateRoute
                        exact
                        path="/checklist"
                        component={Calendar}
                    />
                    <Route path="/users/:username" component={Profile} />
                    {/* <PrivateRoute exact path="/houses" component={Houses} /> */}
                    <PrivateRoute
                        exact
                        path="/houses/:houseId"
                        component={HouseView}
                    />
                    <PrivateRoute
                        exact
                        path="/houses/:houseId/:postId"
                        component={HouseWallDetailView}
                    />
                    <PrivateRoute
                        exact
                        path="/practice/:type"
                        component={Projects}
                    />
                    <PrivateRoute
                        exact
                        path="/practice/:type/:projectId"
                        component={ProjectDetails}
                    />
                    <Route exact path="/companies" component={Companies} />
                    <Route path="/companies/:companyId" component={Company} />
                    <Route exact path="/signin" component={SignIn} />
                    <Route exact path="/signup" component={SignUp} />
                    <Route exact path="/bootcamp/signup" component={SignUp} />
                    <Route
                        exact
                        path="/verify-account"
                        component={VerifyAccount}
                    />
                    <Route
                        exact
                        path="/forgot-password"
                        component={ForgotPassword}
                    />
                    <Route exact path="/error-500" component={Error500} />
                    <Route exact path="/error-503" component={Error503} />
                    <Route exact path="/error-505" component={Error505} />
                    <Route exact path="/access-denied" component={Error401} />
                    <Route path="*" component={ErrorNotFound} />
                </Switch>
            </Suspense>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </Router>
    );
};

export default App;
