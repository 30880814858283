import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import firebase from "firebase";
import { IMentor, IMentorFilter, ISlot } from "src/@types/mentor";
import moment from "moment";

export interface MentorsErrors {
    stacksError: string | null;
    getMentorsError: string | null;
    getMentorError: string | null;
    favoriteMentorsUpdateError: string | null;
}

export enum MentorsStatus {
    Loaded,
    Loading,
    Unloaded,
}

export enum MentorStatus {
    Loaded,
    Loading,
    Unloaded,
}

export interface MentorsState {
    mentorsErrors: MentorsErrors;
    mentorsStatus: MentorsStatus;
    error: string;
    mentors: IMentor[];
    categories: string[];
    allMentors: IMentor[];
    filter: IMentorFilter;
    subject: string;
    filtered: boolean;
    mentor?: IMentor;
    mentorIsLoaded: MentorStatus;
    company: any;
    subscription: any;
    stackList: string[];
    allStacks: string[];
    lastMentorDoc?: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;
    allMentorsLoaded: boolean;
    favoriteMentors?: Record<string, boolean>;
    timeSlots: Record<number, Record<string, string>[]>;
}

const initialState: MentorsState = {
    mentorsErrors: {
        stacksError: null,
        getMentorsError: null,
        favoriteMentorsUpdateError: null,
        getMentorError: null,
    },
    mentorsStatus: MentorsStatus.Loading,
    error: "",
    mentors: [],
    categories: [],
    stackList: [],
    allMentors: [],
    filter: {
        consulting: "all",
        favorites: "all",
        stacks: {},
    },
    subject: "all",
    filtered: false,
    company: {},
    subscription: {},
    mentorIsLoaded: MentorStatus.Loading,
    allStacks: [],
    allMentorsLoaded: false,
    timeSlots: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
    },
};

const mentorsSlice = createSlice({
    name: "mentors",
    initialState,
    reducers: {
        favoriteMentorsUpdateSuccess(
            state,
            action: PayloadAction<{
                favs: Record<string, boolean>;
            }>
        ) {
            const {
                payload: { favs },
            } = action;
            state.favoriteMentors = favs;
        },
        favoriteMentorsUpdateFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.mentorsErrors.favoriteMentorsUpdateError = error;
        },
        getAllStacksSuccess(
            state,
            action: PayloadAction<{
                stacks: string[];
            }>
        ) {
            const {
                payload: { stacks },
            } = action;
            state.allStacks = stacks;
            state.mentorsErrors.stacksError = null;
        },
        getAllStacksFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.mentorsErrors.stacksError = error;
        },
        mentorsSuccess(
            state,
            action: PayloadAction<{
                mentors: IMentor[];
                lastMentorDoc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;
            }>
        ) {
            const {
                payload: { mentors, lastMentorDoc },
            } = action;
            state.mentorsStatus = MentorsStatus.Loaded;
            state.allMentors = mentors;
            state.lastMentorDoc = lastMentorDoc;
            state.mentorsErrors.getMentorsError = null;
        },
        loadMoreMentorsSuccess(
            state,
            action: PayloadAction<{
                mentors: IMentor[];
                lastMentorDoc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;
            }>
        ) {
            const {
                payload: { mentors, lastMentorDoc },
            } = action;
            state.mentorsStatus = MentorsStatus.Loaded;
            if (mentors.length > 0) {
                const oldAllMentors = JSON.parse(
                    JSON.stringify(state.allMentors)
                );
                state.allMentors = oldAllMentors.concat(mentors);
            } else {
                state.allMentorsLoaded = true;
            }

            state.lastMentorDoc = lastMentorDoc;

            state.mentorsErrors.getMentorsError = null;
        },
        mentorSuccess(
            state,
            action: PayloadAction<{
                mentor: IMentor;
            }>
        ) {
            const {
                payload: { mentor },
            } = action;
            state.mentorIsLoaded = MentorStatus.Loaded;
            state.mentor = mentor;
            state.mentorsErrors.getMentorError = null;
            if (mentor && mentor.slots) {
                const myOffset = moment().utcOffset() / 60;
                const mentorSlots = mentor.slots;
                const timeslots: Record<number, Record<string, string>[]> =
                    JSON.parse(JSON.stringify(state.timeSlots));
                [0, 1, 2, 3, 4, 5, 6].forEach((day) => {
                    if (mentorSlots[String(day)]) {
                        mentorSlots[String(day)].forEach((s: ISlot) => {
                            const { start, end } = s;
                            const offsetHours = +start + myOffset;
                            if (offsetHours >= 24) {
                                timeslots[day === 6 ? 0 : +day + 1].push({
                                    start: moment
                                        .utc((+start + myOffset) * 3600 * 1000)
                                        .format("HH:mm"),
                                    end: moment
                                        .utc((+end + myOffset) * 3600 * 1000)
                                        .format("HH:mm"),
                                });
                            } else if (offsetHours < 0) {
                                timeslots[day === 0 ? 6 : day - 1].push({
                                    start: moment
                                        .utc((+start + myOffset) * 3600 * 1000)
                                        .format("HH:mm"),
                                    end: moment
                                        .utc((+end + myOffset) * 3600 * 1000)
                                        .format("HH:mm"),
                                });
                            } else {
                                timeslots[day].push({
                                    start: moment
                                        .utc((+start + myOffset) * 3600 * 1000)
                                        .format("HH:mm"),
                                    end: moment
                                        .utc((+end + myOffset) * 3600 * 1000)
                                        .format("HH:mm"),
                                });
                            }
                        });
                    }
                });
                state.timeSlots = timeslots;
            }
        },
        mentorFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.mentorIsLoaded = MentorStatus.Unloaded;
            state.mentorsErrors.getMentorError = error;
        },
        mentorsFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.mentorsStatus = MentorsStatus.Unloaded;
            state.mentorsErrors.getMentorsError = error;
        },
        filterApplied(
            state,
            action: PayloadAction<{
                filtered: boolean;
            }>
        ) {
            const {
                payload: { filtered },
            } = action;
            state.filtered = filtered;
        },
        filterChanged(
            state,
            action: PayloadAction<{
                filter: IMentorFilter;
            }>
        ) {
            const {
                payload: { filter },
            } = action;
            state.filter = filter;
            let filteredMentors: IMentor[] = JSON.parse(
                JSON.stringify(state.allMentors)
            );

            if (filter.search) {
                const { search } = filter;
                filteredMentors = filteredMentors.filter((mentor) => {
                    const lastName = mentor.lastName.toLowerCase();
                    const firstName = mentor.firstName.toLowerCase();
                    const lowerSearch = search.toLowerCase();
                    return (
                        lastName.startsWith(lowerSearch) ||
                        firstName.startsWith(lowerSearch)
                    );
                });
            }

            if (filter.consulting === "one") {
                filteredMentors = filteredMentors.filter((mentor) => {
                    return !!mentor.priceOne;
                });
            }
            if (filter.consulting === "group") {
                filteredMentors = filteredMentors.filter((mentor) => {
                    return !!mentor.priceGroup;
                });
            }

            if (filter.favorites === "favorites") {
                filteredMentors = filteredMentors.filter((mentor) => {
                    return !!(
                        state.favoriteMentors &&
                        mentor.email in state.favoriteMentors &&
                        state.favoriteMentors[mentor.email]
                    );
                });
            }
            if (Object.keys(filter.stacks).length > 0) {
                const stacks = Object.keys(filter.stacks);
                filteredMentors = filteredMentors.filter((mentor) => {
                    return stacks.some((r) => mentor.stack.indexOf(r) >= 0);
                });
            }
            if (filter.price) {
                const { price } = filter;
                if (filter.consulting === "one") {
                    filteredMentors = filteredMentors.filter((mentor) => {
                        return (
                            mentor.priceOne &&
                            mentor.priceOne >= price.from &&
                            mentor.priceOne <= price.to
                        );
                    });
                } else {
                    filteredMentors = filteredMentors.filter((mentor) => {
                        return (
                            mentor.priceGroup &&
                            mentor.priceGroup >= price.from &&
                            mentor.priceGroup <= price.to
                        );
                    });
                }
            }
            state.mentors = filteredMentors;
        },
        mentorsFiltered(
            state,
            action: PayloadAction<{
                mentors: any[];
            }>
        ) {
            const {
                payload: { mentors },
            } = action;
            state.mentors = mentors;
        },
        sortMentors(
            state,
            action: PayloadAction<{
                sortedMentors: any[];
            }>
        ) {
            const {
                payload: { sortedMentors },
            } = action;
            state.mentors = sortedMentors;
        },
    },
});

export const {
    favoriteMentorsUpdateSuccess,
    favoriteMentorsUpdateFail,
    getAllStacksSuccess,
    getAllStacksFail,
    mentorsSuccess,
    mentorsFail,
    filterChanged,
    mentorsFiltered,
    filterApplied,
    mentorFail,
    mentorSuccess,
    loadMoreMentorsSuccess,
    sortMentors,
} = mentorsSlice.actions;
export default mentorsSlice.reducer;
