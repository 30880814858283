import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ThemeProvider, theme } from "@doar/shared/styled";
import { GlobalStyle } from "@doar/shared/css";
import { store } from "./store/store";
import Auth from "./Auth";
import firebaseService, { FirebaseContext } from "./service";
import "./i18n";

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <FirebaseContext.Provider value={firebaseService}>
                <Auth />
            </FirebaseContext.Provider>
        </ThemeProvider>
    </Provider>,
    document.getElementById("root")
);
