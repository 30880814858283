import { Day, utils } from "react-modern-calendar-datepicker";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import firebase from "firebase";
import moment from "moment";
import { IMeetupEvent, ILectureFilter } from "../../@types/schedule-events";

export interface MeetupsErrors {
    getMeetupsError: string | null;
    updateMeetupAttendeesError: string | null;
    createMeetupError: string | null;
    getLecturesError: string | null;
    favoriteLecturesUpdateError: string | null;
}

export enum LecturesStatus {
    Loaded,
    Loading,
    Unloaded,
}

// Define a type for the slice state

export interface UpdateMeetupAttendeesState {
    category: "groupMeetup" | "personalMeetup" | "publicMeetup";
    eventId: string;
    add: boolean;
    uid: string;
    email: string;
    sum: number;
    avatar: string;
}

export interface EventState {
    meetupsErrors: MeetupsErrors;
    sources: IMeetupEvent[];
    permanentSources: IMeetupEvent[];
    activeCategory: string;
    startOfWeek: string;
    endOfWeek: string;
    days: Date[];
    weekOffset: number;
    lastMeetupDoc?: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;
    allLectures: IMeetupEvent[];
    lectures: IMeetupEvent[];
    currentLecture: IMeetupEvent | null;
    lecturesStatus: LecturesStatus;
    allLecturesLoaded: boolean;
    favoriteLectures?: Record<string, boolean>;
    filter: ILectureFilter;
    mentorCatergory: "groupMeetup" | "personalMeetup";
}

function countDays(startWeek: string, endWeek: string): Date[] {
    const days: Date[] = [];
    let startDay = moment(startWeek, "x");
    const endDay = moment(endWeek, "x");
    while (startDay <= endDay) {
        days.push(startDay.toDate());
        startDay = startDay.clone().add(1, "d");
    }
    return days;
}

// // Define the initial state using that type
const initialState: EventState = {
    meetupsErrors: {
        getMeetupsError: null,
        updateMeetupAttendeesError: null,
        createMeetupError: null,
        getLecturesError: null,
        favoriteLecturesUpdateError: null,
    },
    lectures: [],
    sources: [],
    permanentSources: [],
    activeCategory: "",
    startOfWeek: moment().startOf("isoWeek").format("x"),
    endOfWeek: moment().endOf("isoWeek").format("x"),
    days: countDays(
        moment().startOf("isoWeek").format("x"),
        moment().endOf("isoWeek").format("x")
    ),
    weekOffset: 0,
    allLectures: [],
    lecturesStatus: LecturesStatus.Loading,
    allLecturesLoaded: false,
    currentLecture: null,
    filter: {
        favorites: "all",
        stacks: {},
    },
    mentorCatergory: "groupMeetup",
};

const eventSlice = createSlice({
    name: "meetups",
    initialState,
    reducers: {
        setMentorCategory(
            state,
            action: PayloadAction<{
                category: "groupMeetup" | "personalMeetup";
            }>
        ) {
            const {
                payload: { category },
            } = action;
            state.mentorCatergory = category;
        },
        getMeetupsOfTheWeekSuccess(
            state,
            action: PayloadAction<{
                meetups: IMeetupEvent[];
            }>
        ) {
            const {
                payload: { meetups },
            } = action;
            meetups.forEach((event) => {
                switch (event.category) {
                    case "groupMeetup":
                        event.backgroundColor = "#d9e8ff";
                        event.borderColor = "#0168fa";
                        break;
                    case "personalMeetup":
                        event.backgroundColor = "#fcbfdc";
                        event.borderColor = "#f10075";
                        break;
                    case "publicMeetup":
                        event.backgroundColor = "#c3edd5";
                        event.borderColor = "#10b759";
                        break;
                    default:
                        event.backgroundColor = "#c3edd5";
                        event.borderColor = "#10b759";
                }
            });
            state.sources = meetups;
            state.permanentSources = meetups;
            state.meetupsErrors.getMeetupsError = null;
        },
        updateMeetupAttendeesSuccess(
            state,
            action: PayloadAction<{
                data: UpdateMeetupAttendeesState;
            }>
        ) {
            const {
                payload: { data },
            } = action;
            const sources: IMeetupEvent[] = JSON.parse(
                JSON.stringify(state.permanentSources)
            );

            const lectures: IMeetupEvent[] = JSON.parse(
                JSON.stringify(state.allLectures)
            );

            let changedEvent: IMeetupEvent | undefined;

            if (data.category === "publicMeetup") {
                changedEvent = lectures.find((e) => e.id === data.eventId);
                state.currentLecture = changedEvent as IMeetupEvent;
            } else {
                changedEvent = sources.find((e) => e.id === data.eventId);
            }

            if (changedEvent && changedEvent.attendees && changedEvent.paid) {
                if (data.add) {
                    changedEvent.attendees[data.uid] = {
                        avatar: data.avatar,
                        email: data.email,
                    };
                    changedEvent.paid[data.uid] = data.sum;
                } else {
                    delete changedEvent.attendees[data.uid];
                    delete changedEvent.attendees[data.uid];
                }

                if (data.category === "publicMeetup") {
                    const restLectures = state.lectures.filter(
                        (s) => s.id !== data.eventId
                    );
                    state.allLectures = lectures;
                    state.permanentSources = lectures;
                    state.lectures = [...restLectures, changedEvent];
                } else {
                    const restSources = state.sources.filter(
                        (s) => s.id !== data.eventId
                    );
                    state.permanentSources = sources;
                    state.sources = [...restSources, changedEvent];
                }
            }

            state.meetupsErrors.updateMeetupAttendeesError = null;
        },
        updateMeetupAttendeesFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.meetupsErrors.updateMeetupAttendeesError = error;
        },
        getMeetupsFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.meetupsErrors.getMeetupsError = error;
        },
        filterMeetups(
            state,
            action: PayloadAction<{
                filter: string;
            }>
        ) {
            const {
                payload: { filter },
            } = action;
            if (filter !== "") {
                state.activeCategory = filter;
                state.sources = state.permanentSources.map((source) => {
                    if (source.category !== filter) {
                        return { ...source, events: [] };
                    }
                    return source;
                });
            }
        },
        clearFilter(state) {
            state.activeCategory = "";
            state.sources = state.permanentSources;
        },
        setWeekOffset(
            state,
            action: PayloadAction<{
                offset: number;
            }>
        ) {
            const {
                payload: { offset },
            } = action;
            state.weekOffset = offset;
            state.startOfWeek = moment()
                .add(offset, "weeks")
                .startOf("isoWeek")
                .format("x");
            state.endOfWeek = moment()
                .add(offset, "weeks")
                .endOf("isoWeek")
                .format("x");
            state.days = countDays(state.startOfWeek, state.endOfWeek);
        },
        createMeetupSuccess(
            state,
            action: PayloadAction<{
                meetup: IMeetupEvent;
            }>
        ) {
            const {
                payload: { meetup },
            } = action;
            switch (meetup.category) {
                case "groupMeetup":
                    meetup.backgroundColor = "#d9e8ff";
                    meetup.borderColor = "#0168fa";
                    break;
                case "personalMeetup":
                    meetup.backgroundColor = "#fcbfdc";
                    meetup.borderColor = "#f10075";
                    break;
                case "publicMeetup":
                    meetup.backgroundColor = "#c3edd5";
                    meetup.borderColor = "#10b759";
                    break;
                default:
                    meetup.backgroundColor = "#c3edd5";
                    meetup.borderColor = "#10b759";
            }
            state.sources = [...state.sources, meetup];
            state.permanentSources = [...state.permanentSources, meetup];
            state.meetupsErrors.createMeetupError = null;
        },
        createMeetupFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.meetupsErrors.createMeetupError = error;
        },
        getLecturesSuccess(
            state,
            action: PayloadAction<{
                meetups: IMeetupEvent[];
                lastMeetupDoc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;
            }>
        ) {
            const {
                payload: { meetups, lastMeetupDoc },
            } = action;
            state.lecturesStatus = LecturesStatus.Loaded;
            state.allLectures = meetups;
            state.lastMeetupDoc = lastMeetupDoc;
            state.meetupsErrors.getLecturesError = null;
        },
        getLecturesFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.meetupsErrors.getLecturesError = error;
        },
        loadMoreLecturesSuccess(
            state,
            action: PayloadAction<{
                meetups: IMeetupEvent[];
                lastMeetupDoc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;
            }>
        ) {
            const {
                payload: { meetups, lastMeetupDoc },
            } = action;
            state.lecturesStatus = LecturesStatus.Loaded;
            if (meetups.length > 0) {
                const oldAllLectures = JSON.parse(
                    JSON.stringify(state.allLectures)
                );
                state.allLectures = oldAllLectures.concat(meetups);
            } else {
                state.allLecturesLoaded = true;
            }

            state.lastMeetupDoc = lastMeetupDoc;

            state.meetupsErrors.getLecturesError = null;
        },
        filterChanged(
            state,
            action: PayloadAction<{
                filter: ILectureFilter;
            }>
        ) {
            const {
                payload: { filter },
            } = action;
            state.filter = filter;
            let filteredLectures: IMeetupEvent[] = JSON.parse(
                JSON.stringify(state.allLectures)
            );

            if (filter.search) {
                const { search } = filter;
                filteredLectures = filteredLectures.filter((lecture) => {
                    const title = lecture.title.toLowerCase();
                    const lowerSearch = search.toLowerCase();
                    return title.startsWith(lowerSearch);
                });
            }

            if (filter.favorites === "favorites") {
                filteredLectures = filteredLectures.filter((lecture) => {
                    return !!(
                        state.favoriteLectures &&
                        lecture.id in state.favoriteLectures &&
                        state.favoriteLectures[lecture.id]
                    );
                });
            }
            if (Object.keys(filter.stacks).length > 0) {
                const stacks = Object.keys(filter.stacks);
                filteredLectures = filteredLectures.filter((lecture) => {
                    const lectureStacks = lecture.stacks;
                    if (!lectureStacks) return false;
                    return stacks.some(
                        (r) => Object.keys(lectureStacks).indexOf(r) >= 0
                    );
                });
            }
            if (filter.price) {
                const { price } = filter;
                filteredLectures = filteredLectures.filter((lecture) => {
                    return lecture.sum >= price.from && lecture.sum <= price.to;
                });
            }
            if (filter.date) {
                const { date } = filter;
                filteredLectures = filteredLectures.filter((lecture) => {
                    const lectDay = moment(lecture.start)
                        .format("YYYY-MM-DD")
                        .split("-");
                    const lectObj = {
                        day: Number(lectDay[2]),
                        month: Number(lectDay[1]),
                        year: Number(lectDay[0]),
                    };
                    const func = () => utils("en");
                    const d = func().isBeforeDate.bind(func);
                    // console.log("from", date.from);
                    // console.log("to", date.to);
                    // console.log("lectdate", lectObj);

                    // console.log(d(lectObj, date.from as Day));
                    // console.log(d(date.to as Day, lectObj));

                    return (
                        d(lectObj, date.to as Day) &&
                        d(date.from as Day, lectObj)
                    );
                });
                console.log(filteredLectures);
            }
            state.lectures = filteredLectures;
        },
        favoriteLecturesUpdateSuccess(
            state,
            action: PayloadAction<{
                favs: Record<string, boolean>;
            }>
        ) {
            const {
                payload: { favs },
            } = action;
            state.favoriteLectures = favs;
            state.meetupsErrors.favoriteLecturesUpdateError = null;
        },
        favoriteLecturesUpdateFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.meetupsErrors.favoriteLecturesUpdateError = error;
        },
        setCurrentLecture(
            state,
            action: PayloadAction<{
                lecture: IMeetupEvent;
            }>
        ) {
            const {
                payload: { lecture },
            } = action;
            state.currentLecture = lecture;
        },
    },
});

export const {
    favoriteLecturesUpdateSuccess,
    favoriteLecturesUpdateFail,
    filterChanged,
    loadMoreLecturesSuccess,
    getLecturesSuccess,
    setMentorCategory,
    getLecturesFail,
    setWeekOffset,
    updateMeetupAttendeesSuccess,
    updateMeetupAttendeesFail,
    clearFilter,
    filterMeetups,
    getMeetupsOfTheWeekSuccess,
    getMeetupsFail,
    createMeetupFail,
    setCurrentLecture,
    createMeetupSuccess,
} = eventSlice.actions;

export default eventSlice.reducer;
