import { createSlice } from "@reduxjs/toolkit";

type SolutionArrayItem = {
    id: string;
    ext?: string;
    name?: string;
    url?: string;
    code?: string;
};

interface SolutionsState {
    isLoading: boolean;
    error: string;
    success: boolean;
    solutions: SolutionArrayItem[];
}

const initialState: SolutionsState = {
    isLoading: false,
    error: "",
    success: false,
    solutions: [],
};

const solutionsSlice = createSlice({
    name: "solutions",
    initialState,
    reducers: {
        solutionsPending(state) {
            state.isLoading = true;
        },
        solutionsSuccess(state, action) {
            state.isLoading = false;
            state.error = "";
            state.success = true;
            state.solutions = action.payload;
        },
        solutionsFail(state, action) {
            const {
                payload: { error },
            } = action;
            state.isLoading = false;
            state.error = error;
        },
    },
});

export const { solutionsPending, solutionsSuccess, solutionsFail } =
    solutionsSlice.actions;

export default solutionsSlice.reducer;
