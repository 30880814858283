import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface LoginState {
    isLoading: boolean;
    isAuth: boolean;
    loginError: string | null;
    logoutError: string | null;
}

const initialState: LoginState = {
    isLoading: false,
    isAuth: false,
    loginError: null,
    logoutError: null,
};

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        loginPending(state) {
            state.isLoading = true;
        },
        loginSuccess(state) {
            state.isLoading = false;
            state.isAuth = true;
            state.loginError = null;
        },
        loginFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.isLoading = false;
            state.loginError = error;
        },
        logoutSuccess(state) {
            state.isAuth = false;
            state.logoutError = null;
        },
        logoutFail(
            state,
            action: PayloadAction<{
                error: string;
            }>
        ) {
            const {
                payload: { error },
            } = action;
            state.logoutError = error;
        },
    },
});

export const {
    loginPending,
    loginSuccess,
    loginFail,
    logoutFail,
    logoutSuccess,
} = loginSlice.actions;
export default loginSlice.reducer;
